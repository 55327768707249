import BaseFoundation from "../base/foundation";
export default class PreviewFooterFoundation extends BaseFoundation {
  constructor() {
    super(...arguments);
    this.changeSliderValue = type => {
      const {
        zoom,
        step,
        min,
        max
      } = this.getProps();
      let newValue = type === "plus" ? zoom + step : zoom - step;
      if (newValue > max) {
        newValue = max;
      } else if (newValue < min) {
        newValue = min;
      }
      this.handleValueChange(newValue);
    };
    this.handleValueChange = value => {
      const {
        onZoomIn,
        onZoomOut,
        zoom
      } = this.getProps();
      if (value > zoom) {
        onZoomIn(Number((value / 100).toFixed(2)));
      } else {
        onZoomOut(Number((value / 100).toFixed(2)));
      }
      this._adapter.setStartMouseOffset(value);
    };
    this.handleRatioClick = () => {
      const {
        ratio,
        onAdjustRatio
      } = this.getProps();
      const type = ratio === "adaptation" ? "realSize" : "adaptation";
      onAdjustRatio(type);
    };
    this.handleRotate = direction => {
      const {
        onRotate
      } = this.getProps();
      onRotate && onRotate(direction);
    };
  }
}