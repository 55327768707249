import { BASE_CLASS_PREFIX } from '../base/constants';
const cssClasses = {
  PREFIX: `${BASE_CLASS_PREFIX}-space`
};
const strings = {
  ALIGN_SET: ['start', 'end', 'center', 'baseline'],
  SPACING_LOOSE: 'loose',
  SPACING_MEDIUM: 'medium',
  SPACING_TIGHT: 'tight'
};
export { cssClasses, strings };