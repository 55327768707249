import { PureComponent } from 'react';
import cls from 'classnames';
import PropTypes from 'prop-types';
import { cssClasses } from '@douyinfe/semi-foundation/lib/es/highlight/constants';
import { getHighLightTextHTML } from '../_utils/index';
import '@douyinfe/semi-foundation/lib/es/highlight/highlight.css';
const prefixCls = cssClasses.PREFIX;
class Highlight extends PureComponent {
  render() {
    const {
      searchWords,
      sourceString,
      component,
      highlightClassName,
      highlightStyle,
      caseSensitive,
      autoEscape
    } = this.props;
    const tagCls = cls({
      [`${prefixCls}-tag`]: true
    }, highlightClassName);
    const option = {
      highlightTag: component,
      highlightClassName: tagCls,
      highlightStyle,
      caseSensitive,
      autoEscape
    };
    return getHighLightTextHTML({
      sourceString,
      searchWords,
      option
    });
  }
}
Highlight.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
  autoEscape: PropTypes.bool,
  caseSensitive: PropTypes.bool,
  sourceString: PropTypes.string,
  searchWords: PropTypes.arrayOf(PropTypes.string),
  highlightStyle: PropTypes.object,
  highlightClassName: PropTypes.string,
  component: PropTypes.string
};
Highlight.defaultProps = {
  component: 'mark',
  autoEscape: true,
  caseSensitive: false,
  sourceString: ''
};
export default Highlight;