import BaseFoundation from '../base/foundation';
import isPromise from "../utils/isPromise";
export default class ModalFoundation extends BaseFoundation {
  constructor(adapter) {
    super(Object.assign({}, adapter));
    // afterClose() {
    //     this._adapter.notifyClose();
    // }
    this.toggleDisplayNone = (displayNone, callback) => {
      this._adapter.toggleDisplayNone(displayNone, callback);
    };
  }
  destroy() {
    this.afterHide();
  }
  handleCancel(e) {
    var _a;
    const result = this._adapter.notifyCancel(e);
    if (isPromise(result)) {
      this._adapter.setState({
        onCancelReturnPromiseStatus: "pending"
      });
      (_a = result === null || result === void 0 ? void 0 : result.then(() => {
        this._adapter.setState({
          onCancelReturnPromiseStatus: "fulfilled"
        });
      })) === null || _a === void 0 ? void 0 : _a.catch(e => {
        this._adapter.setState({
          onCancelReturnPromiseStatus: "rejected"
        });
        throw e;
      });
    }
  }
  handleOk(e) {
    var _a;
    const result = this._adapter.notifyOk(e);
    if (isPromise(result)) {
      this._adapter.setState({
        onOKReturnPromiseStatus: "pending"
      });
      (_a = result === null || result === void 0 ? void 0 : result.then(() => {
        this._adapter.setState({
          onOKReturnPromiseStatus: "fulfilled"
        });
      })) === null || _a === void 0 ? void 0 : _a.catch(e => {
        this._adapter.setState({
          onOKReturnPromiseStatus: "rejected"
        });
        throw e;
      });
    }
  }
  beforeShow() {
    this._adapter.disabledBodyScroll();
  }
  afterHide() {
    this._adapter.enabledBodyScroll();
    this._adapter.notifyClose();
  }
}