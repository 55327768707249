/* istanbul ignore next */
class Store {
  constructor(initialState) {
    this._state = Object.assign({}, initialState);
    this._listeners = [];
  }
  subscribe(listener) {
    this._listeners.push(listener);
    const unsubscribe = () => {
      const index = this._listeners.indexOf(listener);
      if (index > -1) {
        this._listeners.splice(index, 1);
      }
    };
    return unsubscribe;
  }
  setState(state) {
    Object.assign(this._state, Object.assign({}, state));
    for (const listener of this._listeners) {
      if (typeof listener === 'function') {
        listener(this._state);
      }
    }
  }
  getState() {
    return this._state;
  }
}
export default Store;