import _without from "lodash/without";
import { isHTMLElement } from "./dom";
/*
* Usage:
*   // Eg1: Pass a dom as the tab tarp container.
*  const handle = new FocusTrapHandle(container, { enable: true });
*
*   // Eg2: The focus redirect listener will be triggered when user pressed tab whiling last focusable dom is focusing in trap dom, return false to cancel redirect and use the browser normal tab focus index.
*   handle.addFocusRedirectListener((e)=>{
*       return true; // return false to prevent redirect on target DOM;
*   });
*
*   // Eg3: Set it to false in order to disable tab tarp at any moment;
*   handle.enable = true;
*
*   // Eg4: Destroy instance when component is unmounting for saving resource;
*   handle.destroy();
*
* */
class FocusTrapHandle {
  constructor(container, options) {
    var _a;
    this.addFocusRedirectListener = listener => {
      this.focusRedirectListenerList.push(listener);
      return () => this.removeFocusRedirectListener(listener);
    };
    this.removeFocusRedirectListener = listener => {
      this.focusRedirectListenerList = _without(this.focusRedirectListenerList, listener);
    };
    this.destroy = () => {
      var _a;
      (_a = this.container) === null || _a === void 0 ? void 0 : _a.removeEventListener('keydown', this.onKeyPress);
    };
    // ---- private func ----
    this.shouldFocusRedirect = element => {
      if (!this.enable) {
        return false;
      }
      for (const listener of this.focusRedirectListenerList) {
        const should = listener(element);
        if (!should) {
          return false;
        }
      }
      return true;
    };
    this.focusElement = (element, event) => {
      const {
        preventScroll
      } = this.options;
      element === null || element === void 0 ? void 0 : element.focus({
        preventScroll
      });
      event.preventDefault(); // prevent browser default tab move behavior
    };

    this.onKeyPress = event => {
      if (event && event.key === 'Tab') {
        const focusableElements = FocusTrapHandle.getFocusableElements(this.container);
        const focusableNum = focusableElements.length;
        if (focusableNum) {
          // Shift + Tab will move focus backward
          if (event.shiftKey) {
            this.handleContainerShiftTabKeyDown(focusableElements, event);
          } else {
            this.handleContainerTabKeyDown(focusableElements, event);
          }
        }
      }
    };
    this.handleContainerTabKeyDown = (focusableElements, event) => {
      const activeElement = FocusTrapHandle.getActiveElement();
      const isLastCurrentFocus = focusableElements[focusableElements.length - 1] === activeElement;
      const redirectForcingElement = focusableElements[0];
      if (isLastCurrentFocus && this.shouldFocusRedirect(redirectForcingElement)) {
        this.focusElement(redirectForcingElement, event);
      }
    };
    this.handleContainerShiftTabKeyDown = (focusableElements, event) => {
      const activeElement = FocusTrapHandle.getActiveElement();
      const isFirstCurrentFocus = focusableElements[0] === activeElement;
      const redirectForcingElement = focusableElements[focusableElements.length - 1];
      if (isFirstCurrentFocus && this.shouldFocusRedirect(redirectForcingElement)) {
        this.focusElement(redirectForcingElement, event);
      }
    };
    Object.freeze(options); // prevent user to change options after init;
    this.container = container;
    this.options = options;
    this.enable = (_a = options === null || options === void 0 ? void 0 : options.enable) !== null && _a !== void 0 ? _a : true;
    this.focusRedirectListenerList = (() => {
      if (options === null || options === void 0 ? void 0 : options.onFocusRedirectListener) {
        return Array.isArray(options.onFocusRedirectListener) ? [...options.onFocusRedirectListener] : [options.onFocusRedirectListener];
      } else {
        return [];
      }
    })();
    this.container.addEventListener('keydown', this.onKeyPress);
  }
  get enable() {
    return this._enable;
  }
  set enable(value) {
    this._enable = value;
  }
  // ---- static func ----
  static getFocusableElements(node) {
    if (!isHTMLElement(node)) {
      return [];
    }
    const focusableSelectorsList = ["input:not([disabled]):not([tabindex='-1'])", "textarea:not([disabled]):not([tabindex='-1'])", "button:not([disabled]):not([tabindex='-1'])", "a[href]:not([tabindex='-1'])", "select:not([disabled]):not([tabindex='-1'])", "area[href]:not([tabindex='-1'])", "iframe:not([tabindex='-1'])", "object:not([tabindex='-1'])", "*[tabindex]:not([tabindex='-1'])", "*[contenteditable]:not([tabindex='-1'])"];
    const focusableSelectorsStr = focusableSelectorsList.join(',');
    // we are not filtered elements which are invisible
    return Array.from(node.querySelectorAll(focusableSelectorsStr));
  }
  static getActiveElement() {
    return document ? document.activeElement : null;
  }
}
export default FocusTrapHandle;