import BaseFoundation from '../base/foundation';
import warning from '../utils/warning';
export default class AvatarFoundation extends BaseFoundation {
  constructor(adapter) {
    super(Object.assign({}, adapter));
    this.handleFocusVisible = event => {
      const {
        target
      } = event;
      try {
        if (target.matches(':focus-visible')) {
          this._adapter.setFocusVisible(true);
        }
      } catch (error) {
        warning(true, 'Warning: [Semi Avatar] The current browser does not support the focus-visible');
      }
    };
    this.handleBlur = () => {
      this._adapter.setFocusVisible(false);
    };
  }
  init() {}
  destroy() {}
  handleImgLoadError() {
    const {
      onError
    } = this.getProps();
    const errorFlag = onError ? onError() : undefined;
    if (errorFlag !== false) {
      this._adapter.notifyImgState(false);
    }
  }
  handleEnter(e) {
    this._adapter.notifyEnter(e);
  }
  handleLeave(e) {
    this._adapter.notifyLeave(e);
  }
}