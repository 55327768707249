import _get from "lodash/get";
import _cloneDeep from "lodash/cloneDeep";
import _pick from "lodash/pick";
import _difference from "lodash/difference";
import _isUndefined from "lodash/isUndefined";
/**
 * The drag and drop handler implementation is referenced from rc-tree
 * https://github.com/react-component/tree
 */
import BaseFoundation from '../base/foundation';
import { flattenTreeData, findDescendantKeys, findAncestorKeys, filter, normalizedArr, normalizeKeyList, getMotionKeys, calcCheckedKeysForChecked, calcCheckedKeysForUnchecked, calcCheckedKeys, getValueOrKey, getDragNodesKeys, calcDropRelativePosition, calcDropActualPosition } from './treeUtil';
export default class TreeFoundation extends BaseFoundation {
  constructor(adapter) {
    super(Object.assign({}, adapter));
    this.clearDragState = () => {
      this._adapter.updateState({
        dragOverNodeKey: '',
        dragging: false
      });
    };
  }
  _isMultiple() {
    return this.getProp('multiple');
  }
  _isAnimated() {
    return this.getProp('motion');
  }
  _isDisabled() {
    let treeNode = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return this.getProp('disabled') || treeNode.disabled;
  }
  _isExpandControlled() {
    return !_isUndefined(this.getProp('expandedKeys'));
  }
  _isLoadControlled() {
    return !_isUndefined(this.getProp('loadedKeys'));
  }
  _isFilterable() {
    // filter can be boolean or function
    return Boolean(this.getProp('filterTreeNode'));
  }
  _showFilteredOnly() {
    const {
      inputValue
    } = this.getStates();
    const {
      showFilteredOnly
    } = this.getProps();
    return Boolean(inputValue) && showFilteredOnly;
  }
  getCopyFromState(items) {
    const res = {};
    normalizedArr(items).forEach(key => {
      res[key] = _cloneDeep(this.getState(key));
    });
    return res;
  }
  getTreeNodeProps(key) {
    const {
      expandedKeys = new Set([]),
      selectedKeys = [],
      checkedKeys = new Set([]),
      halfCheckedKeys = new Set([]),
      realCheckedKeys = new Set([]),
      keyEntities = {},
      filteredKeys = new Set([]),
      inputValue = '',
      loadedKeys = new Set([]),
      loadingKeys = new Set([]),
      filteredExpandedKeys = new Set([]),
      disabledKeys = new Set([])
    } = this.getStates();
    const {
      treeNodeFilterProp,
      checkRelation
    } = this.getProps();
    const entity = keyEntities[key];
    const notExist = !entity;
    if (notExist) {
      return null;
    }
    // if checkRelation is invalid, the checked status of node will be false
    let realChecked = false;
    let realHalfChecked = false;
    if (checkRelation === 'related') {
      realChecked = checkedKeys.has(key);
      realHalfChecked = halfCheckedKeys.has(key);
    } else if (checkRelation === 'unRelated') {
      realChecked = realCheckedKeys.has(key);
      realHalfChecked = false;
    }
    const isSearching = Boolean(inputValue);
    const treeNodeProps = {
      eventKey: key,
      expanded: isSearching ? filteredExpandedKeys.has(key) : expandedKeys.has(key),
      selected: selectedKeys.includes(key),
      checked: realChecked,
      halfChecked: realHalfChecked,
      pos: String(entity ? entity.pos : ''),
      level: entity.level,
      filtered: filteredKeys.has(key),
      loading: loadingKeys.has(key) && !loadedKeys.has(key),
      loaded: loadedKeys.has(key),
      keyword: inputValue,
      treeNodeFilterProp
    };
    if (this.getProp('disableStrictly') && disabledKeys.has(key)) {
      treeNodeProps.disabled = true;
    }
    return treeNodeProps;
  }
  notifyJsonChange(key, e) {
    const data = this.getProp('treeDataSimpleJson');
    const selectedPath = normalizedArr(key).map(i => i.replace('-', '.'));
    const value = _pick(data, selectedPath);
    this._adapter.notifyChange(value);
  }
  notifyMultipleChange(key, e) {
    const {
      keyEntities
    } = this.getStates();
    const {
      leafOnly,
      checkRelation
    } = this.getProps();
    let value;
    let keyList = [];
    if (checkRelation === 'related') {
      keyList = normalizeKeyList(key, keyEntities, leafOnly);
    } else if (checkRelation === 'unRelated') {
      keyList = key;
    }
    if (this.getProp('onChangeWithObject')) {
      value = keyList.map(itemKey => keyEntities[itemKey].data);
    } else {
      value = getValueOrKey(keyList.map(itemKey => keyEntities[itemKey].data));
    }
    this._adapter.notifyChange(value);
  }
  notifyChange(key, e) {
    const isMultiple = this._isMultiple();
    const {
      keyEntities
    } = this.getStates();
    if (this.getProp('treeDataSimpleJson')) {
      this.notifyJsonChange(key, e);
    } else if (isMultiple) {
      this.notifyMultipleChange(key, e);
    } else {
      let value;
      if (this.getProp('onChangeWithObject')) {
        value = _get(keyEntities, key).data;
      } else {
        const {
          data
        } = _get(keyEntities, key);
        value = getValueOrKey(data);
      }
      this._adapter.notifyChange(value);
    }
  }
  handleInputChange(sugInput) {
    // Input is a controlled component, so the value value needs to be updated
    this._adapter.updateInputValue(sugInput);
    const {
      expandedKeys,
      selectedKeys,
      keyEntities,
      treeData
    } = this.getStates();
    const {
      showFilteredOnly,
      filterTreeNode,
      treeNodeFilterProp
    } = this.getProps();
    let filteredOptsKeys = [];
    let expandedOptsKeys = [];
    let flattenNodes = [];
    let filteredShownKeys = new Set([]);
    if (!sugInput) {
      expandedOptsKeys = findAncestorKeys(selectedKeys, keyEntities);
      expandedOptsKeys.forEach(item => expandedKeys.add(item));
      flattenNodes = flattenTreeData(treeData, expandedKeys);
    } else {
      filteredOptsKeys = Object.values(keyEntities).filter(item => filter(sugInput, item.data, filterTreeNode, treeNodeFilterProp)).map(item => item.key);
      expandedOptsKeys = findAncestorKeys(filteredOptsKeys, keyEntities, false);
      const shownChildKeys = findDescendantKeys(filteredOptsKeys, keyEntities, true);
      filteredShownKeys = new Set([...shownChildKeys, ...expandedOptsKeys]);
      flattenNodes = flattenTreeData(treeData, new Set(expandedOptsKeys), showFilteredOnly && filteredShownKeys);
    }
    const newFilteredExpandedKeys = new Set(expandedOptsKeys);
    this._adapter.notifySearch(sugInput, Array.from(newFilteredExpandedKeys));
    this._adapter.updateState({
      expandedKeys,
      flattenNodes,
      motionKeys: new Set([]),
      filteredKeys: new Set(filteredOptsKeys),
      filteredExpandedKeys: newFilteredExpandedKeys,
      filteredShownKeys
    });
  }
  handleNodeSelect(e, treeNode) {
    const isDisabled = this._isDisabled(treeNode);
    if (isDisabled) {
      return;
    }
    if (!this._isMultiple()) {
      this.handleSingleSelect(e, treeNode);
    } else {
      this.handleMultipleSelect(e, treeNode);
    }
  }
  handleNodeRightClick(e, treeNode) {
    this._adapter.notifyRightClick(e, treeNode.data);
  }
  handleNodeDoubleClick(e, treeNode) {
    this._adapter.notifyDoubleClick(e, treeNode.data);
  }
  handleSingleSelect(e, treeNode) {
    let {
      selectedKeys
    } = this.getCopyFromState('selectedKeys');
    const {
      selected,
      eventKey,
      data
    } = treeNode;
    const targetSelected = !selected;
    this._adapter.notifySelect(eventKey, true, data);
    if (!targetSelected) {
      return;
    }
    if (!selectedKeys.includes(eventKey)) {
      selectedKeys = [eventKey];
      this.notifyChange(eventKey, e);
      if (!this._isControlledComponent()) {
        this._adapter.updateState({
          selectedKeys
        });
      }
    }
  }
  calcCheckedKeys(eventKey, targetStatus) {
    const {
      keyEntities
    } = this.getStates();
    const {
      checkedKeys,
      halfCheckedKeys
    } = this.getCopyFromState(['checkedKeys', 'halfCheckedKeys']);
    return targetStatus ? calcCheckedKeysForChecked(eventKey, keyEntities, checkedKeys, halfCheckedKeys) : calcCheckedKeysForUnchecked(eventKey, keyEntities, checkedKeys, halfCheckedKeys);
  }
  /*
  * Compute the checked state of the node
  */
  calcCheckedStatus(targetStatus, eventKey) {
    // From checked to unchecked, you can change it directly
    if (!targetStatus) {
      return targetStatus;
    }
    // Starting from unchecked, you need to judge according to the descendant nodes
    const {
      checkedKeys,
      keyEntities,
      disabledKeys
    } = this.getStates();
    const descendantKeys = normalizeKeyList(findDescendantKeys([eventKey], keyEntities, false), keyEntities, true);
    const hasDisabled = descendantKeys.some(key => disabledKeys.has(key));
    // If the descendant nodes are not disabled, they will be directly changed to checked
    if (!hasDisabled) {
      return targetStatus;
    }
    // If all descendant nodes that are not disabled are selected, return unchecked, otherwise, return checked
    const nonDisabledKeys = descendantKeys.filter(key => !disabledKeys.has(key));
    const allChecked = nonDisabledKeys.every(key => checkedKeys.has(key));
    return !allChecked;
  }
  /*
  * In strict disable mode, calculate the nodes of checked and halfCheckedKeys and return their corresponding keys
  */
  calcNonDisabledCheckedKeys(eventKey, targetStatus) {
    const {
      keyEntities,
      disabledKeys
    } = this.getStates();
    const {
      checkedKeys
    } = this.getCopyFromState(['checkedKeys']);
    const descendantKeys = normalizeKeyList(findDescendantKeys([eventKey], keyEntities, false), keyEntities, true);
    const hasDisabled = descendantKeys.some(key => disabledKeys.has(key));
    // If none of the descendant nodes are disabled, follow the normal logic
    if (!hasDisabled) {
      return this.calcCheckedKeys(eventKey, targetStatus);
    }
    const nonDisabled = descendantKeys.filter(key => !disabledKeys.has(key));
    const newCheckedKeys = targetStatus ? [...nonDisabled, ...checkedKeys] : _difference(normalizeKeyList([...checkedKeys], keyEntities, true), nonDisabled);
    return calcCheckedKeys(newCheckedKeys, keyEntities);
  }
  /*
  * Handle the selection event in the case of multiple selection
  */
  handleMultipleSelect(e, treeNode) {
    const {
      disableStrictly,
      checkRelation
    } = this.getProps();
    const {
      realCheckedKeys
    } = this.getStates();
    // eventKey: The key value of the currently clicked node
    const {
      checked,
      eventKey,
      data
    } = treeNode;
    if (checkRelation === 'related') {
      // Find the checked state of the current node
      const targetStatus = disableStrictly ? this.calcCheckedStatus(!checked, eventKey) : !checked;
      const {
        checkedKeys,
        halfCheckedKeys
      } = disableStrictly ? this.calcNonDisabledCheckedKeys(eventKey, targetStatus) : this.calcCheckedKeys(eventKey, targetStatus);
      this._adapter.notifySelect(eventKey, targetStatus, data);
      this.notifyChange([...checkedKeys], e);
      if (!this._isControlledComponent()) {
        this._adapter.updateState({
          checkedKeys,
          halfCheckedKeys
        });
      }
    } else if (checkRelation === 'unRelated') {
      const newRealCheckedKeys = new Set(realCheckedKeys);
      let targetStatus;
      if (realCheckedKeys.has(eventKey)) {
        newRealCheckedKeys.delete(eventKey);
        targetStatus = false;
      } else {
        newRealCheckedKeys.add(eventKey);
        targetStatus = true;
      }
      this._adapter.notifySelect(eventKey, targetStatus, data);
      this.notifyChange([...newRealCheckedKeys], e);
      if (!this._isControlledComponent()) {
        this._adapter.updateState({
          realCheckedKeys: newRealCheckedKeys
        });
      }
    }
  }
  setExpandedStatus(treeNode) {
    const {
      inputValue,
      treeData,
      filteredShownKeys,
      keyEntities
    } = this.getStates();
    const isSearching = Boolean(inputValue);
    const showFilteredOnly = this._showFilteredOnly();
    const expandedStateKey = isSearching ? 'filteredExpandedKeys' : 'expandedKeys';
    const expandedKeys = this.getCopyFromState(expandedStateKey)[expandedStateKey];
    let motionType = 'show';
    const {
      eventKey,
      expanded,
      data
    } = treeNode;
    if (!expanded) {
      expandedKeys.add(eventKey);
    } else if (expandedKeys.has(eventKey)) {
      expandedKeys.delete(eventKey);
      motionType = 'hide';
    }
    this._adapter.cacheFlattenNodes(motionType === 'hide' && this._isAnimated());
    if (!this._isExpandControlled()) {
      const flattenNodes = flattenTreeData(treeData, expandedKeys, isSearching && showFilteredOnly && filteredShownKeys);
      const motionKeys = this._isAnimated() ? getMotionKeys(eventKey, expandedKeys, keyEntities) : [];
      const newState = {
        [expandedStateKey]: expandedKeys,
        flattenNodes,
        motionKeys: new Set(motionKeys),
        motionType
      };
      this._adapter.updateState(newState);
    }
    return {
      expandedKeys,
      expanded: !expanded,
      data
    };
  }
  handleNodeExpand(e, treeNode) {
    const {
      loadData
    } = this.getProps();
    if (!loadData && (!treeNode.children || !treeNode.children.length)) {
      return;
    }
    const {
      expandedKeys,
      data,
      expanded
    } = this.setExpandedStatus(treeNode);
    this._adapter.notifyExpand(expandedKeys, {
      expanded,
      node: data
    });
  }
  handleNodeLoad(loadedKeys, loadingKeys, data, resolve) {
    const {
      loadData,
      onLoad
    } = this.getProps();
    const {
      key
    } = data;
    if (!loadData || loadedKeys.has(key) || loadingKeys.has(key)) {
      return {};
    }
    // Process the loaded data
    loadData(data).then(() => {
      const {
        loadedKeys: prevLoadedKeys,
        loadingKeys: prevLoadingKeys
      } = this.getCopyFromState(['loadedKeys', 'loadingKeys']);
      const newLoadedKeys = prevLoadedKeys.add(key);
      const newLoadingKeys = new Set([...prevLoadingKeys]);
      newLoadingKeys.delete(key);
      // onLoad should be triggered before internal setState to avoid `loadData` being triggered twice
      onLoad && onLoad(newLoadedKeys, data);
      if (!this._isLoadControlled()) {
        this._adapter.updateState({
          loadedKeys: newLoadedKeys
        });
      }
      this._adapter.setState({
        loadingKeys: newLoadingKeys
      });
      resolve();
    });
    return {
      loadingKeys: loadingKeys.add(key)
    };
  }
  // Drag and drop related processing logic
  getDragEventNodeData(node) {
    return Object.assign(Object.assign({}, node.data), _pick(node, ['expanded', 'pos', 'children']));
  }
  triggerDragEvent(name, event, node) {
    let extra = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
    const callEvent = this.getProp(name);
    callEvent && callEvent(Object.assign({
      event,
      node: this.getDragEventNodeData(node)
    }, extra));
  }
  handleNodeDragStart(e, treeNode) {
    const {
      keyEntities
    } = this.getStates();
    const {
      hideDraggingNode,
      renderDraggingNode
    } = this.getProps();
    const {
      eventKey,
      nodeInstance,
      data
    } = treeNode;
    if (hideDraggingNode || renderDraggingNode) {
      let dragImg;
      if (typeof renderDraggingNode === 'function') {
        dragImg = renderDraggingNode(nodeInstance, data);
      } else if (hideDraggingNode) {
        dragImg = nodeInstance.cloneNode(true);
        dragImg.style.opacity = 0;
      }
      document.body.appendChild(dragImg);
      e.dataTransfer.setDragImage(dragImg, 0, 0);
    }
    this._adapter.setDragNode(treeNode);
    this._adapter.updateState({
      dragging: true,
      dragNodesKeys: new Set(getDragNodesKeys(eventKey, keyEntities))
    });
    this.triggerDragEvent('onDragStart', e, treeNode);
  }
  handleNodeDragEnter(e, treeNode, dragNode) {
    const {
      dragging,
      dragNodesKeys
    } = this.getStates();
    const {
      autoExpandWhenDragEnter
    } = this.getProps();
    const {
      pos,
      eventKey,
      expanded
    } = treeNode;
    if (!dragNode || dragNodesKeys.has(eventKey)) {
      return;
    }
    const dropPosition = calcDropRelativePosition(e, treeNode);
    // If the drag node is itself, skip
    if (dragNode.eventKey === eventKey && dropPosition === 0) {
      this._adapter.updateState({
        dragOverNodeKey: '',
        dropPosition: null
      });
      return;
    }
    // Trigger dragenter after clearing the prev state in dragleave
    setTimeout(() => {
      this._adapter.updateState({
        dragOverNodeKey: eventKey,
        dropPosition
      });
      // If autoExpand is already expanded or not allowed, trigger the event and return
      if (!autoExpandWhenDragEnter || expanded) {
        this.triggerDragEvent('onDragEnter', e, treeNode);
        return;
      }
      // Side effects of delayed drag
      if (!this.delayedDragEnterLogic) {
        this.delayedDragEnterLogic = {};
      }
      Object.keys(this.delayedDragEnterLogic).forEach(key => {
        clearTimeout(this.delayedDragEnterLogic[key]);
      });
      this.delayedDragEnterLogic[pos] = window.setTimeout(() => {
        if (!dragging) {
          return;
        }
        const {
          expandedKeys: newExpandedKeys
        } = this.setExpandedStatus(treeNode);
        this.triggerDragEvent('onDragEnter', e, treeNode, {
          expandedKeys: [...newExpandedKeys]
        });
      }, 400);
    }, 0);
  }
  handleNodeDragOver(e, treeNode, dragNode) {
    const {
      dropPosition,
      dragNodesKeys,
      dragOverNodeKey
    } = this.getStates();
    const {
      eventKey
    } = treeNode;
    if (dragNodesKeys.has(eventKey)) {
      return;
    }
    // Update the drag position
    if (dragNode && eventKey === dragOverNodeKey) {
      const newPos = calcDropRelativePosition(e, treeNode);
      if (dropPosition === newPos) {
        return;
      }
      this._adapter.updateState({
        dropPosition: newPos
      });
    }
    this.triggerDragEvent('onDragOver', e, treeNode);
  }
  handleNodeDragLeave(e, treeNode) {
    this._adapter.updateState({
      dragOverNodeKey: ''
    });
    this.triggerDragEvent('onDragLeave', e, treeNode);
  }
  handleNodeDragEnd(e, treeNode) {
    this.clearDragState();
    this.triggerDragEvent('onDragEnd', e, treeNode);
    this._adapter.setDragNode(null);
  }
  handleNodeDrop(e, treeNode, dragNode) {
    const {
      dropPosition,
      dragNodesKeys
    } = this.getStates();
    const {
      eventKey,
      pos
    } = treeNode;
    this.clearDragState();
    if (dragNodesKeys.has(eventKey)) {
      return;
    }
    const dropRes = {
      dragNode: dragNode ? this.getDragEventNodeData(dragNode) : null,
      dragNodesKeys: [...dragNodesKeys],
      dropPosition: calcDropActualPosition(pos, dropPosition),
      dropToGap: dropPosition !== 0
    };
    this.triggerDragEvent('onDrop', e, treeNode, dropRes);
    this._adapter.setDragNode(null);
  }
}