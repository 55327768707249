import { BASE_CLASS_PREFIX } from '../base/constants';
const cssClasses = {
  PREFIX: `${BASE_CLASS_PREFIX}-badge`
};
const strings = {
  TYPE_SET: ['primary', 'secondary', 'tertiary', 'warning', 'danger'],
  THEME_SET: ['solid', 'light', 'inverted'],
  POS_SET: ['leftTop', 'leftBottom', 'rightTop', 'rightBottom']
};
const numbers = {};
export { cssClasses, strings, numbers };