import _omit from "lodash/omit";
import BaseFoundation from '../base/foundation';
import { strings } from './constants';
import { _generateGroupedData, _generateTreeData } from './transferUtils';
import arrayMove from '../utils/arrayMove';
export default class TransferFoundation extends BaseFoundation {
  constructor(adapter) {
    super(Object.assign({}, adapter));
  }
  _generateGroupedData(dataSource) {
    return _generateGroupedData(dataSource);
  }
  _generateTreeData(dataSource) {
    return _generateTreeData(dataSource);
  }
  _generatePath(item) {
    const {
      path = []
    } = item;
    return path.map(p => p.label).join(' > ');
  }
  handleInputChange(inputVal, notify) {
    const {
      data
    } = this.getStates();
    const {
      filter,
      type
    } = this.getProps();
    if (type === strings.TYPE_TREE_TO_LIST) {
      const searchResult = new Set(data.map(item => item.key));
      this._adapter.searchTree(inputVal);
      notify && this._adapter.notifySearch(inputVal);
      this._adapter.updateInput(inputVal);
      this._adapter.updateSearchResult(searchResult);
      return;
    }
    const filterFunc = typeof filter === 'function' ? item => filter(inputVal, item) : item => typeof item.label === 'string' && item.label.includes(inputVal);
    const searchData = data.filter(filterFunc);
    const searchResult = new Set(searchData.map(item => item.key));
    notify && this._adapter.notifySearch(inputVal);
    this._adapter.updateInput(inputVal);
    this._adapter.updateSearchResult(searchResult);
  }
  // Select or cancel all unhidden items
  handleAll(wantAllChecked) {
    const {
      disabled,
      type
    } = this.getProps();
    const {
      selectedItems,
      data,
      searchResult,
      inputValue
    } = this.getStates();
    if (disabled) {
      return;
    }
    const inSearchMode = inputValue !== '';
    let operateData = [];
    operateData = inSearchMode ? data.filter(item => searchResult.has(item.key)) : data;
    operateData = type === strings.TYPE_TREE_TO_LIST ? data : operateData;
    let newSelectedItems = new Map();
    switch (true) {
      case !wantAllChecked:
        newSelectedItems = new Map(selectedItems);
        operateData.forEach(item => {
          // If the item is disabled, keep it
          if (!item.disabled) {
            newSelectedItems.delete(item.key);
          }
        });
        break;
      case wantAllChecked:
        newSelectedItems = new Map(selectedItems);
        operateData.forEach(item => {
          if (item.disabled) {
            // The disabled item, judge whether it is selected, if it is selected, still need to add the selection
            if (selectedItems.has(item.key)) {
              newSelectedItems.set(item.key, item);
            }
            return;
          }
          newSelectedItems.set(item.key, item);
        });
        break;
      default:
        break;
    }
    if (!this._isControlledComponent()) {
      this._adapter.updateSelected(newSelectedItems);
    }
    this._notifyChange(newSelectedItems);
  }
  handleClear() {
    const {
      disabled
    } = this.getProps();
    const {
      selectedItems,
      data
    } = this.getStates();
    if (disabled) {
      return;
    }
    const newSelectedItems = new Map(selectedItems);
    data.forEach(item => {
      // If the item is disabled, keep it
      if (!item.disabled) {
        newSelectedItems.delete(item.key);
      }
    });
    if (!this._isControlledComponent()) {
      this._adapter.updateSelected(newSelectedItems);
    }
    this._notifyChange(newSelectedItems);
  }
  handleSelectOrRemove(item) {
    const {
      disabled
    } = this.getProps();
    const selectedItems = this._adapter.getSelected();
    if (disabled || item.disabled) {
      return;
    }
    if (selectedItems.has(item.key)) {
      selectedItems.delete(item.key);
      this._adapter.notifyDeselect(item);
    } else {
      selectedItems.set(item.key, item);
      this._adapter.notifySelect(item);
    }
    if (!this._isControlledComponent()) {
      this._adapter.updateSelected(selectedItems);
    }
    this._notifyChange(selectedItems);
  }
  handleSelect(values) {
    const {
      disabled
    } = this.getProps();
    const selectedItems = this._adapter.getSelected();
    const {
      data
    } = this.getStates();
    const dataItems = data.map(d => [d.value, d]);
    const allItemsMap = new Map(dataItems);
    const nextSelectedItemsMap = new Map();
    if (disabled) {
      return;
    }
    values.forEach(value => {
      const node = allItemsMap.get(value);
      // The value passed in is an array of the value used, but the internal selectedItems stores a map of keys
      if (selectedItems.has(node.key)) {
        nextSelectedItemsMap.set(node.key, node);
        return;
      }
      if (node.disabled) {
        return;
      }
      nextSelectedItemsMap.set(node.key, node);
      return;
    });
    if (!this._isControlledComponent()) {
      this._adapter.updateSelected(nextSelectedItemsMap);
    }
    this._notifyChange(nextSelectedItemsMap);
  }
  getValuesAndItemsFromMap(selectedItems) {
    const {
      type
    } = this.getProps();
    const items = [];
    const values = [];
    for (const item of selectedItems) {
      const obj = type === strings.TYPE_GROUP_LIST ? _omit(item[1], '_parent') : item[1];
      items.push(obj);
      values.push(obj.value);
    }
    return {
      items,
      values
    };
  }
  _notifyChange(selectedItems) {
    const {
      items,
      values
    } = this.getValuesAndItemsFromMap(selectedItems);
    this._adapter.notifyChange(values, items);
  }
  handleSortEnd(callbackProps) {
    const {
      oldIndex,
      newIndex
    } = callbackProps;
    const selectedItems = this._adapter.getSelected();
    let selectedArr = [...selectedItems.values()];
    selectedArr = arrayMove(selectedArr, oldIndex, newIndex);
    let newSelectedItems = new Map();
    selectedArr.forEach(option => {
      newSelectedItems = newSelectedItems.set(option.key, option);
    });
    this._adapter.updateSelected(newSelectedItems);
    this._notifyChange(newSelectedItems);
  }
}