import _map from "lodash/map";
import _filter from "lodash/filter";
import _split from "lodash/split";
import classnames from 'classnames';
function addClass(rawCls) {
  const clss = _split(rawCls, /\s+/);
  for (var _len = arguments.length, srcClss = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
    srcClss[_key - 1] = arguments[_key];
  }
  const validClss = _filter(srcClss, cls => !clss.includes(cls));
  return classnames(rawCls, ...validClss);
}
function removeClass(rawCls) {
  const clss = _split(rawCls, /\s+/);
  for (var _len2 = arguments.length, srcClss = new Array(_len2 > 1 ? _len2 - 1 : 0), _key2 = 1; _key2 < _len2; _key2++) {
    srcClss[_key2 - 1] = arguments[_key2];
  }
  _map(srcClss, cls => {
    const index = clss.indexOf(cls);
    if (index > -1) {
      clss.splice(index, 1);
    }
  });
  return classnames(...clss);
}
export { addClass, removeClass };