import _isNumber from "lodash/isNumber";
import BaseFoundation from '../base/foundation';
export default class ToastFoundation extends BaseFoundation {
  constructor(adapter) {
    super(Object.assign(Object.assign({}, ToastFoundation.defaultAdapter), adapter));
    this._timer = null;
    this._id = null; // cache id
  }

  init() {
    this.startCloseTimer_();
    this._id = this._adapter.getProp('id');
  }
  destroy() {
    this.clearCloseTimer_();
  }
  startCloseTimer_() {
    // unit: s
    const duration = this._adapter.getProp('duration');
    if (duration && _isNumber(duration)) {
      this._timer = setTimeout(() => {
        this.close(); // call parent to remove itself
      }, duration * 1000);
    }
  }
  close(e) {
    if (e) {
      e.stopPropagation();
    }
    this._adapter.notifyWrapperToRemove(this._id);
    this._adapter.notifyClose();
  }
  clearCloseTimer_() {
    if (this._timer) {
      clearTimeout(this._timer);
      this._timer = null;
    }
  }
  restartCloseTimer() {
    this.clearCloseTimer_();
    this.startCloseTimer_();
  }
}