import { BASE_CLASS_PREFIX } from '../base/constants';
const cssClasses = {
  DIALOG: `${BASE_CLASS_PREFIX}-modal`
};
const strings = {
  cancelKey: 'cancel',
  confirmKey: 'confirm',
  SIZE: ['small', 'medium', 'large', 'full-width'],
  directions: ['ltr', 'rtl']
};
export { cssClasses, strings };