import _isNumber from "lodash/isNumber";
import _isArray from "lodash/isArray";
import _isString from "lodash/isString";
/**
 * Get the splited array.
 * We expect separators to be string | string[] | null, but users
 * are also allowed to pass in other types.
 */
const getSplitedArray = (originString, separators) => {
  let splitedValue = [];
  if (_isString(separators) || _isNumber(separators)) {
    splitedValue = originString.split(separators);
  } else if (_isArray(separators)) {
    const tempChar = separators[0]; // temporary splitter
    splitedValue = originString;
    for (let i = 1; i < separators.length; i++) {
      splitedValue = splitedValue.split(separators[i]).join(tempChar);
    }
    splitedValue = splitedValue.split(tempChar);
  } else {
    splitedValue.push(originString);
  }
  return splitedValue;
};
export default getSplitedArray;