import useFormApi from './useFormApi';
const buildFieldApi = (formApi, field) => ({
  getError: () => formApi.getError(field),
  setError: error => formApi.setError(field, error),
  getTouched: () => formApi.getTouched(field),
  setTouched: isTouched => formApi.setTouched(field, isTouched),
  getValue: () => formApi.getValue(field),
  setValue: value => formApi.setValue(field, value)
});
function useFieldApi(field) {
  const formApi = useFormApi();
  const fieldApi = buildFieldApi(formApi, field);
  return fieldApi;
}
export default useFieldApi;