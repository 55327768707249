import _isEqual from "lodash/isEqual";
import _isUndefined from "lodash/isUndefined";
import _isNull from "lodash/isNull";
import { strings } from './constants';
function getPosition(level, index) {
  return `${level}-${index}`;
}
export function isValid(val) {
  return !_isNull(val) && !_isUndefined(val);
}
export function normalizedArr(val) {
  if (!Array.isArray(val)) {
    return [val];
  } else {
    return val;
  }
}
/**
 * Traverse all the data by `treeData`.
 */
function traverseDataNodes(treeNodes, callback) {
  const processNode = (node, ind, parent) => {
    const children = node ? node.children : treeNodes;
    let item = null;
    // Process node if is not root
    if (node) {
      const key = parent ? getPosition(parent.key, ind) : `${ind}`;
      item = {
        data: Object.assign({}, node),
        ind,
        key,
        level: parent ? parent.level + 1 : 0,
        parentKey: parent ? parent.key : null,
        path: parent ? [...parent.path, key] : [key],
        valuePath: parent ? [...parent.valuePath, node.value] : [node.value]
      };
      callback(item);
    }
    // Process children node
    if (children) {
      children.forEach((subNode, subIndex) => {
        processNode(subNode, subIndex, item);
      });
    }
  };
  processNode(null);
}
export function convertDataToEntities(dataNodes) {
  const keyEntities = {};
  traverseDataNodes(dataNodes, data => {
    const {
      key,
      parentKey
    } = data;
    const entity = Object.assign({}, data);
    keyEntities[key] = entity;
    // Fill children
    entity.parent = keyEntities[parentKey];
    if (entity.parent) {
      entity.parent.children = entity.parent.children || [];
      entity.parent.children.push(entity);
    }
  });
  return keyEntities;
}
export function findKeysForValues(value, keyEntities) {
  const valuePath = normalizedArr(value);
  const res = Object.values(keyEntities).filter(item => _isEqual(item.valuePath, valuePath)).map(item => item.key);
  return res;
}
export function calcMergeType(autoMergeValue, leafOnly) {
  let mergeType;
  if (leafOnly) {
    mergeType = strings.LEAF_ONLY_MERGE_TYPE;
  } else if (autoMergeValue) {
    mergeType = strings.AUTO_MERGE_VALUE_MERGE_TYPE;
  } else {
    mergeType = strings.NONE_MERGE_TYPE;
  }
  return mergeType;
}