import _noop from "lodash/noop";
/**
 * The Semi Foundation / Adapter architecture split was inspired by Material Component For Web. （https://github.com/material-components/material-components-web）
 * We re-implemented our own code based on the principle and added more functions we need according to actual needs.
 *
 */
import log from '../utils/log';
class BaseFoundation {
  /** @return enum{css className} */
  /* istanbul ignore next */
  static get cssClasses() {
    // Classes extending Foundation should implement this method to return an object which exports every
    // CSS class the foundation class needs as a property. e.g. {ACTIVE: 'component--active'}
    return {};
  }
  /** @return enum{strings} */
  /* istanbul ignore next */
  static get strings() {
    // Classes extending Foundation should implement this method to return an object which exports all
    // semantic strings as constants. e.g. {ARIA_ROLE: 'tablist'}
    return {};
  }
  /** @return enum{numbers} */
  /* istanbul ignore next */
  static get numbers() {
    // Classes extending Foundation should implement this method to return an object which exports all
    // of its semantic numbers as constants. e.g. {ANIMATION_DELAY_MS: 350}
    return {};
  }
  static get defaultAdapter() {
    return {
      getProp: _noop,
      getProps: _noop,
      getState: _noop,
      getStates: _noop,
      setState: _noop,
      getContext: _noop,
      getContexts: _noop,
      getCache: _noop,
      setCache: _noop,
      getCaches: _noop,
      stopPropagation: _noop,
      persistEvent: _noop
    };
  }
  constructor(adapter) {
    this._adapter = Object.assign(Object.assign({}, BaseFoundation.defaultAdapter), adapter);
  }
  getProp(key) {
    return this._adapter.getProp(key);
  }
  getProps() {
    return this._adapter.getProps();
  }
  getState(key) {
    return this._adapter.getState(key);
  }
  getStates() {
    return this._adapter.getStates();
  }
  setState(states, cb) {
    return this._adapter.setState(Object.assign({}, states), cb);
  }
  getContext(key) {
    return this._adapter.getContext(key);
  }
  /* istanbul ignore next */
  getContexts() {
    return this._adapter.getContexts();
  }
  /* istanbul ignore next */
  getCaches() {
    return this._adapter.getCaches();
  }
  getCache(key) {
    return this._adapter.getCache(key);
  }
  setCache(key, value) {
    return key && this._adapter.setCache(key, value);
  }
  stopPropagation(e) {
    this._adapter.stopPropagation(e);
  }
  // Determine whether a controlled component
  _isControlledComponent() {
    let key = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'value';
    const props = this.getProps();
    const isControlComponent = (key in props);
    return isControlComponent;
  }
  // Does the user have incoming props, eg: _isInProps (value)
  _isInProps(key) {
    const props = this.getProps();
    return key in props;
  }
  init(lifecycle) {
    // Subclasses should override this method to perform initialization routines (registering events, etc.)
  }
  destroy() {
    // Subclasses should override this method to perform de-initialization routines (de-registering events, etc.)
  }
  /* istanbul ignore next */
  log(text) {
    for (var _len = arguments.length, rest = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
      rest[_key - 1] = arguments[_key];
    }
    log(text, ...rest);
  }
  _persistEvent(e) {
    // only work for react adapter for now
    this._adapter.persistEvent(e);
  }
}
export default BaseFoundation;