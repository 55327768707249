import { BASE_CLASS_PREFIX } from '../base/constants';
const cssClasses = {
  PREFIX: `${BASE_CLASS_PREFIX}-transfer`
};
const strings = {
  TYPE_GROUP_LIST: 'groupList',
  TYPE_LIST: 'list',
  TYPE_TREE_TO_LIST: 'treeList'
};
const numbers = {};
export { cssClasses, strings, numbers };