import _cloneDeep from "lodash/cloneDeep";
import { setMonth, setYear } from 'date-fns';
import BaseFoundation from '../base/foundation';
import { strings } from './constants';
export default class YearAndMonthFoundation extends BaseFoundation {
  constructor(adapter) {
    super(Object.assign({}, adapter));
  }
  init() {}
  destroy() {}
  selectYear(item, panelType) {
    // const year = item.value;
    const {
      currentYear,
      currentMonth
    } = this.getStates();
    const {
      type
    } = this.getProps();
    const left = strings.PANEL_TYPE_LEFT;
    const right = strings.PANEL_TYPE_RIGHT;
    const year = _cloneDeep(currentYear);
    year[panelType] = item.value;
    // make sure the right panel time is always less than the left panel time
    if (type === 'monthRange') {
      const isSameYearIllegalDate = year[left] === year[right] && currentMonth[left] > currentMonth[right];
      if (panelType === left && item.value > year[right] || panelType === left && isSameYearIllegalDate) {
        // 1. select left year and left year > right year
        // 2. select left year, left year = right year, but left date > right date
        year[right] = item.value + 1;
      } else if (panelType === right && isSameYearIllegalDate) {
        // 1. select right year, left year = right year, but left date > right date
        year[left] = item.value - 1;
      }
    }
    this._adapter.setCurrentYear(year, () => this.autoSelectMonth(item, panelType, year));
    this._adapter.notifySelectYear(year);
  }
  selectMonth(item, panelType) {
    const {
      currentMonth,
      currentYear
    } = this.getStates();
    const {
      type
    } = this.getProps();
    const left = strings.PANEL_TYPE_LEFT;
    const right = strings.PANEL_TYPE_RIGHT;
    const month = _cloneDeep(currentMonth);
    month[panelType] = item.month;
    // make sure the right panel time is always less than the left panel time
    if (type === 'monthRange' && panelType === left && currentYear[left] === currentYear[right] && item.value > month[right]) {
      month[right] = item.month + 1;
    }
    this._adapter.setCurrentMonth(month);
    this._adapter.notifySelectMonth(month);
  }
  /**
   * After selecting a year, if the currentMonth is disabled, automatically select a non-disabled month
   */
  autoSelectMonth(item, panelType, year) {
    const {
      disabledDate,
      locale
    } = this._adapter.getProps();
    const {
      months,
      currentMonth
    } = this._adapter.getStates();
    const currentDate = setYear(Date.now(), item.year);
    const isCurrentMonthDisabled = disabledDate(setMonth(currentDate, currentMonth[panelType] - 1));
    if (isCurrentMonthDisabled) {
      const currentIndex = months.findIndex(_ref => {
        let {
          month
        } = _ref;
        return month === currentMonth[panelType];
      });
      let validMonth;
      // First look in the back, if you can't find it in the back, then look in the front
      validMonth = months.slice(currentIndex).find(_ref2 => {
        let {
          month
        } = _ref2;
        return !disabledDate(setMonth(currentDate, month - 1));
      });
      if (!validMonth) {
        validMonth = months.slice(0, currentIndex).find(_ref3 => {
          let {
            month
          } = _ref3;
          return !disabledDate(setMonth(currentDate, month - 1));
        });
      }
      if (validMonth) {
        const month = _cloneDeep(currentMonth);
        month[panelType] = validMonth.month;
        // change year and month same time
        this._adapter.setCurrentYearAndMonth(year, month);
        this._adapter.notifySelectYearAndMonth(year, month);
      }
    }
  }
  backToMain() {
    this._adapter.notifyBackToMain();
  }
}