import _get from "lodash/get";
import BaseFoundation from '../base/foundation';
import NavItem from './NavItem';
import { strings } from './constants';
import isNullOrUndefined from '../utils/isNullOrUndefined';
export default class NavigationFoundation extends BaseFoundation {
  constructor(adapter) {
    super(Object.assign({}, adapter));
  }
  /* istanbul ignore next */
  static getZeroParentKeys() {
    let itemKeysMap = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    const willAddKeys = [];
    for (var _len = arguments.length, itemKeys = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
      itemKeys[_key - 1] = arguments[_key];
    }
    if (itemKeys.length) {
      for (const itemKey of itemKeys) {
        if (Array.isArray(itemKeysMap[itemKey]) && itemKeysMap[itemKey].length) {
          const levelZeroParentKey = itemKeysMap[itemKey][0];
          if (!isNullOrUndefined(levelZeroParentKey)) {
            willAddKeys.push(levelZeroParentKey);
          }
        }
      }
    }
    return willAddKeys;
  }
  static buildItemKeysMap() {
    let items = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    let keysMap = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    let parentKeys = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
    let keyPropName = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 'itemKey';
    if (Array.isArray(items) && items.length) {
      for (const item of items) {
        if (Array.isArray(item)) {
          NavigationFoundation.buildItemKeysMap(item, keysMap, [...parentKeys], keyPropName);
        } else {
          let itemKey;
          if (item && typeof item === 'object') {
            itemKey = item[keyPropName] || item.props && item.props[keyPropName];
          }
          if (itemKey) {
            keysMap[itemKey] = [...parentKeys];
            if (Array.isArray(item.items) && item.items.length) {
              NavigationFoundation.buildItemKeysMap(item.items, keysMap, [...parentKeys, itemKey], keyPropName);
            } else if (item.props && item.props.children) {
              const children = Array.isArray(item.props.children) ? item.props.children : [item.props.children];
              NavigationFoundation.buildItemKeysMap(children, keysMap, [...parentKeys, itemKey], keyPropName);
            }
          }
        }
      }
    }
    return keysMap;
  }
  /**
   * init is called in constructor and componentDidMount.
   * if you want to update state in constructor, please add it to return object;
   * if you want to update state in componentDidMount, please call adapter in else logic.
   * @param {*} lifecycle
   * @returns
   */
  init(lifecycle) {
    const {
      defaultSelectedKeys,
      selectedKeys
    } = this.getProps();
    let willSelectedKeys = selectedKeys || defaultSelectedKeys || [];
    const {
      itemKeysMap,
      willOpenKeys,
      formattedItems
    } = this.getCalcState();
    const parentSelectKeys = this.selectLevelZeroParentKeys(itemKeysMap, willSelectedKeys);
    willSelectedKeys = willSelectedKeys.concat(parentSelectKeys);
    if (lifecycle === 'constructor') {
      return {
        selectedKeys: willSelectedKeys,
        itemKeysMap,
        openKeys: willOpenKeys,
        items: formattedItems
      };
    } else {
      this._adapter.updateSelectedKeys(willSelectedKeys);
      this._adapter.setItemKeysMap(itemKeysMap);
      this._adapter.updateOpenKeys(willOpenKeys);
      this._adapter.updateItems(formattedItems);
      this._adapter.setItemsChanged(true);
    }
    return undefined;
  }
  /**
   * Get the state to be calculated
   */
  getCalcState() {
    const {
      itemKeysMap,
      formattedItems
    } = this.getFormattedItems();
    const willOpenKeys = this.getWillOpenKeys(itemKeysMap);
    return {
      itemKeysMap,
      willOpenKeys,
      formattedItems
    };
  }
  /**
   * Calculate formatted items and itemsKeyMap
   */
  getFormattedItems() {
    const {
      items,
      children
    } = this.getProps();
    const formattedItems = this.formatItems(items);
    const willHandleItems = Array.isArray(items) && items.length ? formattedItems : children;
    const itemKeysMap = NavigationFoundation.buildItemKeysMap(willHandleItems);
    return {
      itemKeysMap,
      formattedItems
    };
  }
  /**
   * Calculate the keys that will need to be opened soon
   * @param {*} itemKeysMap
   */
  getWillOpenKeys(itemKeysMap) {
    const {
      defaultOpenKeys,
      openKeys,
      defaultSelectedKeys,
      selectedKeys,
      mode
    } = this.getProps();
    let willOpenKeys = openKeys || defaultOpenKeys || [];
    if (!(Array.isArray(defaultOpenKeys) || Array.isArray(openKeys)) && mode === strings.MODE_VERTICAL && (Array.isArray(defaultSelectedKeys) || Array.isArray(selectedKeys))) {
      const currentSelectedKeys = Array.isArray(selectedKeys) ? selectedKeys : defaultSelectedKeys;
      willOpenKeys = this.getShouldOpenKeys(itemKeysMap, currentSelectedKeys);
    }
    return [...willOpenKeys];
  }
  getShouldOpenKeys() {
    let itemKeysMap = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    let selectedKeys = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
    const willOpenKeySet = new Set();
    if (Array.isArray(selectedKeys) && selectedKeys.length) {
      selectedKeys.forEach(item => {
        if (item) {
          const parentKeys = _get(itemKeysMap, item);
          if (Array.isArray(parentKeys)) {
            parentKeys.forEach(k => willOpenKeySet.add(k));
          }
        }
      });
    }
    return [...willOpenKeySet];
  }
  destroy() {}
  selectLevelZeroParentKeys(itemKeysMap) {
    const _itemKeysMap = isNullOrUndefined(itemKeysMap) ? this.getState('itemKeysMap') : itemKeysMap;
    // console.log(itemKeysMap);
    const willAddKeys = [];
    for (var _len2 = arguments.length, itemKeys = new Array(_len2 > 1 ? _len2 - 1 : 0), _key2 = 1; _key2 < _len2; _key2++) {
      itemKeys[_key2 - 1] = arguments[_key2];
    }
    if (itemKeys.length) {
      for (const itemKey of itemKeys) {
        if (Array.isArray(_itemKeysMap[itemKey]) && _itemKeysMap[itemKey].length) {
          const levelZeroParentKey = _itemKeysMap[itemKey][0];
          if (!isNullOrUndefined(levelZeroParentKey)) {
            willAddKeys.push(levelZeroParentKey);
          }
        }
      }
    }
    if (willAddKeys.length) {
      return willAddKeys;
    }
    return [];
  }
  formatItems() {
    let items = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    const formattedItems = [];
    for (const item of items) {
      formattedItems.push(new NavItem(item));
    }
    return formattedItems;
  }
  handleSelect(data) {
    this._adapter.notifySelect(data);
  }
  /* istanbul ignore next */
  judgeIfOpen(openKeys, items) {
    let shouldBeOpen = false;
    const _openKeys = Array.isArray(openKeys) ? openKeys : openKeys && [openKeys];
    if (_openKeys && Array.isArray(items) && items.length) {
      for (const item of items) {
        shouldBeOpen = _openKeys.includes(item.itemKey) || this.judgeIfOpen(_openKeys, item.items);
        if (shouldBeOpen) {
          break;
        }
      }
    }
    return shouldBeOpen;
  }
  handleCollapseChange() {
    const isCollapsed = !this.getState('isCollapsed');
    if (!this._isControlledComponent('isCollapsed')) {
      this._adapter.setIsCollapsed(isCollapsed);
    }
    this._adapter.notifyCollapseChange(isCollapsed);
  }
  handleItemsChange(isChanged) {
    this._adapter.setItemsChanged(isChanged);
  }
}