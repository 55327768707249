import { BASE_CLASS_PREFIX } from '../base/constants';
const cssClasses = {
  PREFIX: `${BASE_CLASS_PREFIX}-form`
};
const strings = {
  LAYOUT: ['horizontal', 'vertical'],
  LABEL_POS: ['left', 'top', 'inset'],
  LABEL_ALIGN: ['left', 'right'],
  EXTRA_POS: ['middle', 'bottom']
};
const numbers = {};
export { cssClasses, strings, numbers };