import BaseFoundation from '../base/foundation';
export default class TableRowFoundation extends BaseFoundation {
  handleClick(e) {
    const {
      expanded,
      rowKey
    } = this.getProps();
    this._adapter.notifyClick(rowKey, e, expanded);
  }
  handleDoubleClick(e) {
    this._adapter.notifyDoubleClick(this.getProp('record'), e);
  }
  handleMouseEnter(e) {
    const record = this.getProp('record');
    this._adapter.notifyMouseEnter(record, e);
  }
  handleMouseLeave(e) {
    const record = this.getProp('record');
    this._adapter.notifyMouseLeave(record, e);
  }
}