import _isString from "lodash/isString";
import _isNumber from "lodash/isNumber";
import _isFunction from "lodash/isFunction";
import _noop from "lodash/noop";
import BaseFoundation from '../base/foundation';
import calculateNodeHeight from './util/calculateNodeHeight';
import getSizingData from './util/getSizingData';
export default class TextAreaFoundation extends BaseFoundation {
  static get textAreaDefaultAdapter() {
    return {
      notifyChange: _noop,
      setValue: _noop,
      toggleFocusing: _noop,
      toggleHovering: _noop,
      notifyFocus: _noop,
      notifyBlur: _noop,
      notifyKeyDown: _noop,
      notifyEnterPress: _noop
    };
  }
  constructor(adapter) {
    super(Object.assign(Object.assign({}, TextAreaFoundation.textAreaDefaultAdapter), adapter));
    this.resizeTextarea = cb => {
      const {
        height
      } = this.getStates();
      const {
        rows
      } = this.getProps();
      const node = this._adapter.getRef();
      const nodeSizingData = getSizingData(node);
      if (!nodeSizingData) {
        cb && cb();
        return;
      }
      const newHeight = calculateNodeHeight(nodeSizingData, node.value || node.placeholder || 'x', rows
      // maxRows,
      );

      if (height !== newHeight) {
        this._adapter.notifyHeightUpdate(newHeight);
        node.style.height = `${newHeight}px`;
        return;
      }
      cb && cb();
    };
  }
  init() {
    this.setInitValue();
  }
  destroy() {}
  setInitValue() {
    const {
      defaultValue,
      value
    } = this.getProps();
    let v = defaultValue;
    if (this._isControlledComponent()) {
      v = value;
    }
    this._adapter.setValue(v);
  }
  handleValueChange(v) {
    this._adapter.setValue(v);
  }
  handleChange(value, e) {
    const {
      maxLength,
      minLength,
      getValueLength
    } = this._adapter.getProps();
    let nextValue = value;
    if (maxLength && _isFunction(getValueLength)) {
      nextValue = this.handleVisibleMaxLength(value);
    }
    if (minLength && _isFunction(getValueLength)) {
      this.handleVisibleMinLength(nextValue);
    }
    if (this._isControlledComponent()) {
      this._adapter.notifyChange(nextValue, e);
    } else {
      this._adapter.setValue(nextValue);
      this._adapter.notifyChange(nextValue, e);
    }
  }
  /**
   * Modify minLength to trigger browser check for minimum length
   * Controlled mode is not checked
   * @param {String} value
   */
  handleVisibleMinLength(value) {
    const {
      minLength,
      getValueLength
    } = this._adapter.getProps();
    const {
      minLength: stateMinLength
    } = this._adapter.getStates();
    if (_isNumber(minLength) && minLength >= 0 && _isFunction(getValueLength) && _isString(value)) {
      const valueLength = getValueLength(value);
      if (valueLength < minLength) {
        const newMinLength = value.length + (minLength - valueLength);
        newMinLength !== stateMinLength && this._adapter.setMinLength(newMinLength);
      } else {
        stateMinLength !== minLength && this._adapter.setMinLength(minLength);
      }
    }
  }
  /**
   * Handle input emoji characters beyond maxLength
   * Controlled mode is not checked
   * @param {String} value
   */
  handleVisibleMaxLength(value) {
    const {
      maxLength,
      getValueLength
    } = this._adapter.getProps();
    if (_isNumber(maxLength) && maxLength >= 0 && _isFunction(getValueLength) && _isString(value)) {
      const valueLength = getValueLength(value);
      if (valueLength > maxLength) {
        console.warn('[Semi TextArea] The input character is truncated because the input length exceeds the maximum length limit');
        const truncatedValue = this.handleTruncateValue(value, maxLength);
        return truncatedValue;
      } else {
        return value;
      }
    }
    return undefined;
  }
  /**
   * Truncate textarea values based on maximum length
   * @param {String} value
   * @param {Number} maxLength
   * @returns {String}
   */
  handleTruncateValue(value, maxLength) {
    const {
      getValueLength
    } = this._adapter.getProps();
    if (_isFunction(getValueLength)) {
      let truncatedValue = '';
      for (let i = 1, len = value.length; i <= len; i++) {
        const currentValue = value.slice(0, i);
        if (getValueLength(currentValue) > maxLength) {
          return truncatedValue;
        } else {
          truncatedValue = currentValue;
        }
      }
      return truncatedValue;
    } else {
      return value.slice(0, maxLength);
    }
  }
  handleFocus(e) {
    const {
      value
    } = this.getStates();
    this._adapter.toggleFocusing(true);
    this._adapter.notifyFocus(value, e);
  }
  handleBlur(e) {
    const {
      value
    } = this.getStates();
    this._adapter.toggleFocusing(false);
    this._adapter.notifyBlur(value, e);
  }
  handleKeyDown(e) {
    this._adapter.notifyKeyDown(e);
    if (e.keyCode === 13) {
      this._adapter.notifyPressEnter(e);
    }
  }
  // e: MouseEvent
  handleMouseEnter(e) {
    this._adapter.toggleHovering(true);
  }
  // e: MouseEvent
  handleMouseLeave(e) {
    this._adapter.toggleHovering(false);
  }
  isAllowClear() {
    const {
      value,
      isFocus,
      isHover
    } = this._adapter.getStates();
    const {
      showClear,
      disabled,
      readonly
    } = this._adapter.getProps();
    const allowClear = value && showClear && !disabled && (isFocus || isHover) && !readonly;
    return allowClear;
  }
  handleClear(e) {
    const {
      isFocus
    } = this.getStates();
    if (this._isControlledComponent('value')) {
      this._adapter.setState({
        isFocus: false
      });
    } else {
      this._adapter.setState({
        value: '',
        isFocus: false
      });
    }
    if (isFocus) {
      this._adapter.notifyBlur('', e);
    }
    this._adapter.notifyChange('', e);
    this._adapter.notifyClear(e);
    this.stopPropagation(e);
  }
}