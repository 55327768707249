import _isUndefined from "lodash/isUndefined";
import _split from "lodash/split";
import { strings } from './constants';
import BaseFoundation from '../base/foundation';
import { formatToString, parseToDate, hourIsDisabled, minuteIsDisabled, secondIsDisabled, transformToArray, isTimeFormatLike } from './utils';
import { isValid, format, getHours } from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc } from '../utils/date-fns-extra';
import isNullOrUndefined from '../utils/isNullOrUndefined';
// TODO: split, timePicker different components cannot share a foundation
class TimePickerFoundation extends BaseFoundation {
  constructor(adapter) {
    super(Object.assign({}, adapter));
  }
  init() {
    this.initDataFromDefaultValue();
    const open = this._isControlledComponent('open') ? this.getProp('open') : this.getProp('defaultOpen');
    if (open && !this._isControlledComponent('open')) {
      this._adapter.registerClickOutSide();
    }
  }
  getPosition() {
    const position = this.getProp('position');
    const type = this.getProp('type') || strings.DEFAULT_TYPE;
    // rtl change default position
    const direction = this.getContext('direction');
    const rtlDirection = direction === 'rtl' ? 'bottomRight' : '';
    return position || rtlDirection || strings.DEFAULT_POSITION[type];
  }
  isDisabledHMS(_ref) {
    let {
      hours,
      minutes,
      seconds
    } = _ref;
    const {
      disabledHours,
      disabledMinutes,
      disabledSeconds
    } = this.getProps();
    const hDis = !isNullOrUndefined(hours) && hourIsDisabled(disabledHours, hours);
    const mDis = !isNullOrUndefined(hours) && !isNullOrUndefined(minutes) && minuteIsDisabled(disabledMinutes, hours, minutes);
    const sDis = !isNullOrUndefined(hours) && !isNullOrUndefined(minutes) && !isNullOrUndefined(seconds) && secondIsDisabled(disabledSeconds, hours, minutes, seconds);
    return hDis || mDis || sDis;
  }
  isValidTimeZone(timeZone) {
    return ['string', 'number'].includes(typeof timeZone) && timeZone !== '';
  }
  getDefaultFormatIfNeed() {
    if (this._isInProps('format')) {
      return this.getProp('format');
    } else if (this.getProp('use12Hours')) {
      return strings.DEFAULT_FORMAT_A;
    } else {
      return strings.DEFAULT_FORMAT;
    }
  }
  /**
   * User input value => save timestamp
   */
  initDataFromDefaultValue() {
    const defaultValue = this.getProp('defaultValue');
    let value = this.getProp('value');
    const timeZone = this.getProp('timeZone');
    const formatToken = this.getValidFormat();
    const {
      rangeSeparator,
      dateFnsLocale
    } = this.getProps();
    value = value || defaultValue;
    if (!Array.isArray(value)) {
      value = value ? [value] : [];
    }
    const parsedValues = [];
    let invalid = false;
    value.forEach(v => {
      const pv = parseToDate(v, formatToken, dateFnsLocale);
      if (!isNaN(pv.getTime())) {
        parsedValues.push(this.isValidTimeZone(timeZone) ? utcToZonedTime(pv, timeZone) : pv);
      }
    });
    const isAM = [true, false];
    parsedValues.map((item, idx) => {
      isAM[idx] = getHours(item) < 12;
    });
    if (parsedValues.length === value.length) {
      value = parsedValues;
    } else {
      value = [];
      if (value.length) {
        invalid = true;
      }
    }
    let inputValue = '';
    if (!invalid) {
      inputValue = value.map(v => formatToString(v, formatToken, dateFnsLocale)).join(rangeSeparator);
    }
    this.setState({
      isAM,
      value,
      inputValue,
      invalid
    });
  }
  getValidFormat(validFormat) {
    let _format = validFormat;
    if (isNullOrUndefined(_format)) {
      _format = this.getDefaultFormatIfNeed();
    }
    if (typeof _format !== 'string') {
      _format = strings.DEFAULT_FORMAT;
    }
    return _format;
  }
  handlePanelChange(result) {
    let index = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
    // console.log(result, index);
    const formatToken = this.getValidFormat();
    const dateFnsLocale = this.getProp('dateFnsLocale');
    const oldValue = this.getState('value');
    let isAM = this.getState('isAM');
    const value = transformToArray(oldValue);
    isAM = transformToArray(isAM);
    if (result) {
      const panelIsAM = Boolean(result.isAM);
      const date = parseToDate(result.timeStampValue, formatToken, dateFnsLocale);
      value[index] = date;
      isAM[index] = panelIsAM;
      const inputValue = this.formatValue(value);
      if (this.getState('isAM')[index] !== result.isAM) {
        this.setState({
          isAM
        });
      }
      if (!this._isControlledComponent('value')) {
        const invalid = this.validateDates(value);
        this.setState({
          isAM,
          value,
          inputValue,
          invalid
        });
      }
      if (this._hasChanged(value, oldValue)) {
        this._notifyChange(value, inputValue);
      }
    }
  }
  refreshProps() {
    let props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    const {
      value,
      timeZone,
      __prevTimeZone
    } = props;
    let dates = this.parseValue(value);
    const invalid = this.validateDates(dates);
    if (!invalid) {
      if (this.isValidTimeZone(timeZone)) {
        dates = dates.map(date => utcToZonedTime(this.isValidTimeZone(__prevTimeZone) ? zonedTimeToUtc(date, __prevTimeZone) : date, timeZone));
      }
    }
    const inputValue = this.formatValue(dates);
    this.setState({
      value: dates,
      invalid,
      inputValue
    });
  }
  handleFocus(e) {
    if (!this.getState('open')) {
      this.handlePanelOpen();
    }
    this._adapter.notifyFocus(e);
  }
  setPanel(open) {
    this._adapter.togglePanel(open);
  }
  destroy() {
    this._adapter.unregisterClickOutSide();
  }
  handlePanelOpen() {
    if (!this._isControlledComponent('open')) {
      this._adapter.registerClickOutSide();
      this.setPanel(true);
    }
    this._adapter.notifyOpenChange(true);
  }
  handlePanelClose(clickedOutside, e) {
    if (!this._isControlledComponent('open')) {
      this._adapter.unregisterClickOutSide();
      this.setPanel(false);
    }
    this._adapter.notifyOpenChange(false);
    this._adapter.notifyBlur(e);
  }
  /* istanbul ignore next */
  handleVisibleChange(visible) {
    if (!this._isControlledComponent('open')) {
      this._adapter.togglePanel(visible);
    }
    this._adapter.notifyOpenChange(visible);
  }
  handleInputChange(input) {
    this._adapter.setInputValue(input);
    const rangeSeparator = this.getProp('rangeSeparator');
    const inputValues = _split(input, rangeSeparator);
    const formatToken = this.getValidFormat();
    /**
     * 如果输入的字符串不是formatLike则不进行下一步操作，以免输入过程被打断
     * 特殊case
     *  - 清空时，input 为 ''，此时需要跳过isTimeFormatLike判断
     *
     * If the input string is not formatLike, do not proceed to the next operation to avoid interruption of the input process
     *  special case
     *  -when emptying, the input is "', at this time you need to skip isTimeFormatLike judgment
     */
    if (input !== '' && inputValues.some(time => !isTimeFormatLike(time, formatToken))) {
      return;
    }
    const dates = this.parseInput(input);
    const invalid = this.validateDates(dates);
    const states = {
      invalid
    };
    const oldValue = this.getState('value');
    let value = transformToArray(oldValue);
    if (!invalid) {
      states.value = dates;
      value = [...dates];
    }
    if (!this._isControlledComponent('value')) {
      this.setState(states);
    }
    if (this._hasChanged(value, oldValue)) {
      this._notifyChange(value, input);
    }
  }
  /* istanbul ignore next */
  doValidate(args) {
    if (typeof args === 'string') {
      return this.validateStr(args);
    } else if (Array.isArray(args)) {
      return this.validateDates(args);
    }
    return undefined;
  }
  validateStr() {
    let inputValue = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
    const dates = this.parseInput(inputValue);
    return this.validateDates(dates);
  }
  validateDates() {
    let dates = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    let invalid = dates.some(d => isNaN(Number(d)));
    if (!invalid) {
      invalid = dates.some(d => this.isDisabledHMS({
        hours: d.getHours(),
        minutes: d.getMinutes(),
        seconds: d.getSeconds()
      }));
    }
    return invalid;
  }
  handleInputBlur(e) {
    const invalid = this.getState('invalid');
    const inputValue = this.getState('inputValue');
    const value = this.getState('value');
    if (inputValue) {
      if (invalid) {
        this.setState({
          inputValue: this.formatValue(value),
          invalid: false
        });
      } else {
        this.setState({
          inputValue: this.formatValue(value)
        });
      }
    } else {
      this.setState({
        inputValue: '',
        value: [],
        invalid: false
      });
    }
  }
  formatValue(dates) {
    const validFormat = this.getValidFormat();
    const rangeSeparator = this.getProp('rangeSeparator');
    const dateFnsLocale = this.getProp('dateFnsLocale');
    let _dates = dates;
    if (_dates && !Array.isArray(_dates)) {
      _dates = _dates[_dates];
    }
    if (_dates && Array.isArray(_dates)) {
      const result = _dates.map(date => {
        let str;
        if (_isUndefined(date)) {
          str = '';
        } else {
          str = formatToString(date, validFormat, dateFnsLocale);
        }
        return str;
      });
      return result.join(rangeSeparator);
    }
    return undefined;
  }
  parseInput(str) {
    const validFormat = this.getValidFormat();
    const rangeSeparator = this.getProp('rangeSeparator');
    const dateFnsLocale = this.getProp('dateFnsLocale');
    if (str && typeof str === 'string') {
      return _split(str, rangeSeparator).map(v => parseToDate(v, validFormat, dateFnsLocale));
    }
    return [];
  }
  parseValue() {
    let value = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    const formatToken = this.getValidFormat();
    const dateFnsLocale = this.getProp('dateFnsLocale');
    let _value = value;
    if (!Array.isArray(_value)) {
      _value = _value ? [_value] : [];
    }
    if (Array.isArray(_value)) {
      return _value.map(v => parseToDate(v, formatToken, dateFnsLocale));
    }
    return [];
  }
  _notifyChange(value, inputValue) {
    let str = inputValue;
    let _value = value;
    const timeZone = this.getProp('timeZone');
    if (this._adapter.isRangePicker()) {
      const rangeSeparator = this.getProp('rangeSeparator');
      str = _split(inputValue, rangeSeparator);
    } else {
      _value = Array.isArray(_value) ? _value[0] : _value;
    }
    if (this.isValidTimeZone(timeZone) && _value) {
      const formatToken = this.getValidFormat();
      if (Array.isArray(_value)) {
        _value = _value.map(v => zonedTimeToUtc(v, timeZone));
        str = _value.map(v => format(v, formatToken));
      } else {
        _value = zonedTimeToUtc(_value, timeZone);
        str = format(_value, formatToken);
      }
    }
    const onChangeWithDateFirst = this.getProp('onChangeWithDateFirst');
    if (onChangeWithDateFirst) {
      this._adapter.notifyChange(_value, str);
    } else {
      this._adapter.notifyChange(str, _value);
    }
  }
  _hasChanged() {
    let dates = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    let oldDates = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
    const formatToken = this.getValidFormat();
    const dateFnsLocale = this.getProp('dateFnsLocale');
    return dates.length !== oldDates.length || dates.some((date, index) => {
      const oldDate = oldDates[index];
      if (isValid(date) && isValid(oldDate) && formatToString(date, formatToken, dateFnsLocale) === formatToString(oldDate, formatToken, dateFnsLocale)) {
        return false;
      }
      return true;
    });
  }
}
export default TimePickerFoundation;