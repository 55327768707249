import _isUndefined from "lodash/isUndefined";
import _isEqual from "lodash/isEqual";
import _slice from "lodash/slice";
import _isMap from "lodash/isMap";
import _filter from "lodash/filter";
import _isSet from "lodash/isSet";
import _pull from "lodash/pull";
import _some from "lodash/some";
import _find from "lodash/find";
import _each from "lodash/each";
import _isFunction from "lodash/isFunction";
import _merge from "lodash/merge";
import _get from "lodash/get";
import memoizeOne from 'memoize-one';
import BaseFoundation from '../base/foundation';
import { strings, numbers } from './constants';
import { mergeQueries, flattenColumns, filterColumns } from './utils';
import { pullAll, withOrderSort } from '../utils/array';
class TableFoundation extends BaseFoundation {
  /**
   * update columns in place, and use default values as initial values if the sorting and filtering columns have no values
   */
  static initColumnsFilteredValueAndSorterOrder(columns) {
    columns.forEach(column => {
      TableFoundation.initFilteredValue(column);
      TableFoundation.initSorterOrder(column);
    });
    return columns;
  }
  /**
   * init filteredValue of filtering column, use defaultFilteredValue or [] when it is undefined
   */
  static initFilteredValue(column) {
    const {
      defaultFilteredValue,
      filteredValue
    } = column;
    // There may be cases where onFilter is empty, such as server-side filtering
    // Because filterValue affects the output of filters, it needs to be initialized here
    if (_isUndefined(filteredValue)) {
      if (Array.isArray(defaultFilteredValue) && defaultFilteredValue.length) {
        column.filteredValue = defaultFilteredValue;
      } else {
        column.filteredValue = [];
      }
    }
  }
  /**
   * init sortOrder of sorting column, use defaultSortOrder or [] when it is undefined
   */
  static initSorterOrder(column) {
    const {
      defaultSortOrder,
      sortOrder,
      sorter
    } = column;
    if (sorter && _isUndefined(sortOrder)) {
      if (!_isUndefined(defaultSortOrder)) {
        column.sortOrder = defaultSortOrder;
      } else {
        column.sortOrder = false;
      }
    }
  }
  constructor(adapter) {
    super(Object.assign({}, adapter));
    /**
     * set page number
     */
    this.setPage = (currentPage, currentPageSize) => {
      currentPage = currentPage || this._adapter.getCurrentPage();
      const currentPagination = this.getState('pagination');
      const {
        dataSource,
        pagination,
        disabledRowKeys,
        allRowKeys
      } = this.getCurrentPageData(null, Object.assign(Object.assign({}, currentPagination), {
        currentPage,
        pageSize: currentPageSize
      }));
      if (!this._pagerIsControlled() && currentPage > 0) {
        this._adapter.setDisabledRowKeys(disabledRowKeys);
        this._adapter.setAllRowKeys(allRowKeys);
        this._adapter.setPagination(pagination);
        this._adapter.setDataSource(dataSource);
      }
      this._notifyChange(pagination);
    };
    /**
     * Cache related data when initializing or updating the calculated dataSource
     * @param {*} filteredSortedDataSource
     */
    this.setCachedFilteredSortedDataSource = filteredSortedDataSource => {
      this._adapter.setCachedFilteredSortedDataSource(filteredSortedDataSource);
      const filteredSortedRowKeys = this.getAllRowKeys(filteredSortedDataSource);
      this._adapter.setCachedFilteredSortedRowKeys(filteredSortedRowKeys);
    };
    this.isSortOrderValid = sortOrder => strings.SORT_DIRECTIONS.includes(sortOrder) || sortOrder === false;
    /**
     * memoized function list
     */
    const handleColumns = this._adapter.getHandleColumns();
    const mergePagination = this._adapter.getMergePagination();
    this.memoizedWithFnsColumns = memoizeOne(handleColumns, _isEqual);
    this.memoizedFilterColumns = memoizeOne(filterColumns);
    this.memoizedFlattenFnsColumns = memoizeOne(flattenColumns);
    this.memoizedPagination = memoizeOne(mergePagination, _isEqual);
  }
  init() {
    const dataSource = [...this.getProp('dataSource')];
    const {
      queries
    } = this._adapter.getStates();
    const filteredSortedDataSource = this.getFilteredSortedDataSource(dataSource, queries);
    const allDataDisabledRowKeys = this.getAllDisabledRowKeys(filteredSortedDataSource);
    const pageData = this.getCurrentPageData(filteredSortedDataSource);
    this.setAdapterPageData(pageData);
    this.initExpandedRowKeys(pageData);
    this.initSelectedRowKeys(pageData);
    // cache dataSource after mount, and then calculate it on demand
    this.setCachedFilteredSortedDataSource(filteredSortedDataSource);
    this.setAllDisabledRowKeys(allDataDisabledRowKeys);
  }
  initExpandedRowKeys() {
    let {
      groups
    } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    const {
      defaultExpandAllRows,
      defaultExpandedRowKeys = [],
      expandedRowKeys: propExpandedRowKeys = [],
      dataSource = [],
      expandAllRows,
      defaultExpandAllGroupRows,
      expandAllGroupRows
    } = this.getProps();
    const expandedRowKeys = [];
    if (defaultExpandAllRows || expandAllRows) {
      this._addNoDuplicatedItemsToArr(expandedRowKeys, this.getAllRowKeys(dataSource), groups && _isMap(groups) && groups.size ? Array.from(groups.keys()) : []);
    } else if (defaultExpandAllGroupRows || expandAllGroupRows) {
      this._addNoDuplicatedItemsToArr(expandedRowKeys, groups && _isMap(groups) && groups.size ? Array.from(groups.keys()) : []);
    } else if (Array.isArray(defaultExpandedRowKeys) && defaultExpandedRowKeys.length) {
      this._addNoDuplicatedItemsToArr(expandedRowKeys, defaultExpandedRowKeys);
    } else if (Array.isArray(propExpandedRowKeys) && propExpandedRowKeys.length) {
      this._addNoDuplicatedItemsToArr(expandedRowKeys, propExpandedRowKeys);
    }
    this._adapter.setExpandedRowKeys(expandedRowKeys);
  }
  initSelectedRowKeys(_ref) {
    let {
      disabledRowKeys
    } = _ref;
    const rowSelection = this.getProp('rowSelection');
    const rowKeys = [];
    if (rowSelection) {
      const selectedRowKeys = _get(rowSelection, 'selectedRowKeys');
      const defaultSelectedRowKeys = _get(rowSelection, 'defaultSelectedRowKeys');
      if (Array.isArray(selectedRowKeys)) {
        this._addNoDuplicatedItemsToArr(rowKeys, selectedRowKeys);
      } else if (Array.isArray(defaultSelectedRowKeys)) {
        this._addNoDuplicatedItemsToArr(rowKeys, defaultSelectedRowKeys);
      }
      if (Array.isArray(disabledRowKeys) && disabledRowKeys.length) {
        _pull(rowKeys, ...disabledRowKeys);
      }
      this._adapter.setSelectedRowKeys(rowKeys);
    }
  }
  /**
   * Get filtered and sorted data
   * @param {Object[]} dataSource
   * @param {Object[]} queries
   * @returns {Object[]} sortedDataSource
   */
  getFilteredSortedDataSource(dataSource, queries) {
    const filteredDataSource = this.filterDataSource(dataSource, queries.filter(query => {
      const currentFilteredValue = query.filteredValue ? query.filteredValue : query.defaultFilteredValue;
      return _isFunction(query.onFilter) && Array.isArray(currentFilteredValue) && currentFilteredValue.length;
    }));
    const sortedDataSource = this.sortDataSource(filteredDataSource, queries.filter(query => query && _isFunction(query.sorter)));
    return sortedDataSource;
  }
  /**
   * get current page data
   *
   * @param {Array} dataSource
   * @param {object} pagination
   * @param {object} queries
   * @returns {{dataSource: RecordType[], groups: Map<string, Set<string>>, pagination: object, disabledRowKeys: string[], queries: BaseColumnProps[], allRowKeys: string[]}}
   */
  getCurrentPageData(dataSource, pagination, queries) {
    const filteredSortedDataSource = this._adapter.getCachedFilteredSortedDataSource();
    dataSource = dataSource == null ? [...filteredSortedDataSource] : dataSource;
    pagination = pagination == null ? this.getState('pagination') && Object.assign({}, this.getState('pagination')) : pagination;
    queries = queries == null ? [...this.getState('queries')] : queries;
    let groups;
    if (this.getProp('groupBy') != null) {
      const {
        groups: groupedGroups,
        dataSource: groupedData
      } = this.groupDataSource(dataSource);
      dataSource = groupedData;
      groups = groupedGroups;
    }
    pagination = this.normalizePagination(pagination, dataSource);
    dataSource = this.limitPageDataSource(dataSource, pagination);
    const disabledRowKeys = this.getAllDisabledRowKeys(dataSource);
    const allRowKeys = this.getAllRowKeys(dataSource);
    const pageData = {
      dataSource,
      groups,
      pagination,
      disabledRowKeys,
      allRowKeys,
      queries
    };
    return pageData;
  }
  /**
   * group dataSource, return grouped row keys
   *
   * @param {*[]} dataSource
   * @param {Function|string} groupBy
   */
  groupDataSource(dataSource, groupBy) {
    groupBy = groupBy == null ? this.getProp('groupBy') : groupBy;
    const groups = new Map();
    const newDataSource = [];
    if (groupBy != null) {
      _each(dataSource, (record, index) => {
        const groupKey = typeof groupBy === 'function' ? groupBy(record) : _get(record, groupBy);
        if (groupKey != null && groupKey !== '') {
          const recordKey = this.getRecordKey(record);
          let group = groups.get(groupKey);
          if (!_isSet(group)) {
            group = new Set([recordKey]);
            groups.set(groupKey, group);
          } else {
            group.add(recordKey);
          }
        }
      });
    }
    if (groups && groups.size) {
      groups.forEach((set, key) => {
        if (_isSet(set)) {
          set.forEach(realKey => {
            newDataSource.push(this._getRecord(realKey));
          });
        }
      });
    } else {
      newDataSource.push(...dataSource);
    }
    return {
      groups,
      dataSource: newDataSource
    };
  }
  /**
   * sort data
   *
   * @param {Array} dataSource
   * @param {Array} sorters
   * @returns {Array}
   */
  sortDataSource(dataSource, sorters) {
    _each(sorters, sorterObj => {
      // const sorterObj = last(sorters) || {};
      const {
        sorter,
        sortOrder,
        defaultSortOrder,
        sortChildrenRecord
      } = sorterObj;
      const currentSortOrder = this.isSortOrderValid(sortOrder) ? sortOrder : defaultSortOrder;
      if (_isFunction(sorter) && currentSortOrder && strings.SORT_DIRECTIONS.includes(currentSortOrder)) {
        if (sortChildrenRecord) {
          const childrenRecordName = this.getProp('childrenRecordName');
          dataSource = dataSource && dataSource.map(record => {
            const children = this._getRecordChildren(record);
            if (Array.isArray(children) && children.length) {
              return Object.assign(Object.assign({}, record), {
                [childrenRecordName]: this.sortDataSource(children, [sorterObj])
              });
            }
            return record;
          });
        }
        dataSource.sort(withOrderSort(sorter, currentSortOrder));
        return false;
      }
      return undefined;
    });
    return dataSource;
  }
  /**
   * filter data source
   *
   * @param {*[]} dataSource
   * @param {*[]} filters
   * @returns {*[]}
   */
  filterDataSource(dataSource, filters) {
    let filteredData = null;
    let hasValidFilters = false;
    const childrenRecordName = this.getProp('childrenRecordName');
    _each(filters, filterObj => {
      const {
        onFilter,
        filteredValue,
        filterChildrenRecord,
        defaultFilteredValue
      } = filterObj;
      const currentFilteredValue = Array.isArray(filteredValue) ? filteredValue : defaultFilteredValue;
      if (typeof onFilter === 'function' && Array.isArray(currentFilteredValue) && currentFilteredValue.length) {
        hasValidFilters = true;
        if (filteredData === null) {
          filteredData = new Map();
        } else {
          dataSource = Array.from(filteredData && filteredData.values());
          filteredData = new Map();
        }
        _each(dataSource, record => {
          _each(currentFilteredValue, value => {
            const childrenRecords = _get(record, childrenRecordName);
            const recordKey = this.getRecordKey(record);
            let filteredChildren;
            if (Array.isArray(childrenRecords) && childrenRecords.length && filterChildrenRecord) {
              filteredChildren = this.filterDataSource(childrenRecords, [filterObj]);
            }
            if (Array.isArray(filteredChildren) && filteredChildren.length) {
              if (recordKey != null) {
                const children = _get(filteredData.get(recordKey), childrenRecordName, []);
                filteredData.set(recordKey, Object.assign(Object.assign({}, record), {
                  [childrenRecordName]: filteredChildren.reduce((arr, cur) => {
                    if (arr.find(item => this.getRecordKey(item) === this.getRecordKey(cur)) == null) {
                      arr.push(cur);
                    }
                    return arr;
                  },
                  // @ts-ignore
                  [...children])
                }));
              }
            } else if (onFilter(value, record)) {
              filteredData.set(recordKey, record);
            }
          });
        });
      }
    });
    if (hasValidFilters) {
      dataSource = Array.from(filteredData && filteredData.values());
    }
    return dataSource;
  }
  limitPageDataSource(dataSource, pagination) {
    dataSource = dataSource == null ? this.getProp('dataSource') : dataSource;
    pagination = pagination == null ? this.getState('pagination') : pagination;
    let pageData = dataSource;
    const pageNo = _get(pagination, 'currentPage');
    if (this.getProp('pagination') !== false && pageNo && dataSource && pagination && !this._pagerIsControlled()) {
      const {
        pageSize = numbers.DEFAULT_PAGE_SIZE
      } = pagination;
      const start = (pageNo - 1) * pageSize;
      const end = pageNo * pageSize;
      pageData = _slice(dataSource, start, end);
    }
    return pageData;
  }
  normalizePagination(pagination, dataSource) {
    pagination = pagination == null ? this._getPagination() : pagination;
    dataSource = dataSource == null ? this._getDataSource() : dataSource;
    const propPagination = this.getProp('pagination');
    if (pagination) {
      pagination = typeof pagination === 'object' ? Object.assign({}, pagination) : {};
      pagination = _merge({
        total: dataSource && dataSource.length || 0,
        pageSize: numbers.DEFAULT_PAGE_SIZE,
        currentPage: _get(propPagination, 'defaultCurrentPage', 1),
        position: strings.PAGINATION_POSITIONS[0]
      }, pagination);
      if (!this._pagerIsControlled()) {
        const total = _get(propPagination, 'total', dataSource.length);
        const pageSize = _get(propPagination, 'pageSize', pagination.pageSize);
        const {
          currentPage
        } = pagination;
        const realTotalPage = Math.ceil(total / pageSize);
        pagination.total = total;
        if (currentPage > realTotalPage) {
          pagination.currentPage = 1;
        }
      }
    }
    return pagination;
  }
  setAdapterPageData() {
    let pageData = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    const {
      pagination,
      dataSource,
      disabledRowKeys,
      allRowKeys,
      groups
    } = pageData;
    this._adapter.setDisabledRowKeys(disabledRowKeys);
    this._adapter.setAllRowKeys(allRowKeys);
    this._adapter.setPagination(pagination);
    this._adapter.setGroups(groups);
    this._adapter.setDataSource(dataSource);
  }
  destroy() {}
  setAllDisabledRowKeys(disabledRowKeys) {
    this._adapter.setAllDisabledRowKeys(disabledRowKeys);
  }
  handleClick(e) {}
  handleMouseEnter(e) {}
  handleMouseLeave(e) {}
  stopPropagation(e) {
    this._adapter.stopPropagation(e);
  }
  /**
   * Add non-repeating elements to the array itself
   * @param {Array} srcArr
   * @param {Object} objArrs
   */
  _addNoDuplicatedItemsToArr() {
    let srcArr = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    for (var _len = arguments.length, objArrs = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
      objArrs[_key - 1] = arguments[_key];
    }
    for (const objArr of objArrs) {
      if (Array.isArray(objArr)) {
        for (const item of objArr) {
          if (!srcArr.includes(item)) {
            srcArr.push(item);
          }
        }
      }
    }
    return srcArr;
  }
  _notifyChange(pagination, filters, sorter, extra) {
    pagination = pagination == null ? this._getPagination() : pagination;
    filters = filters == null ? this._getAllFilters() : filters;
    sorter = sorter == null ? this._getAllSorters()[0] : sorter;
    if (_get(this.getProp('scroll'), 'scrollToFirstRowOnChange')) {
      this._adapter.resetScrollY();
    }
    this._adapter.notifyChange({
      pagination: Object.assign({}, pagination),
      filters: [...filters],
      sorter,
      extra: Object.assign({}, extra)
    });
  }
  _rowExpansionIsControlled() {
    return Array.isArray(this.getProp('expandedRowKeys'));
  }
  _pagerIsControlled() {
    return _get(this.getProp('pagination'), 'currentPage') != null;
  }
  _selectionIsControlled() {
    return Array.isArray(_get(this.getProp('rowSelection'), 'selectedRowKeys'));
  }
  /**
   * Determine whether the column sorting is controlled
   * Controlled: the column passed the sortOrder prop
   * @param {String} dataIndex
   * @returns {Boolean}
   */
  _sorterIsControlled(dataIndex) {
    // The basis for judgment should be props columns instead of cachedColumns fix#1141
    const query = dataIndex && this.getQuery(dataIndex, this.getState('flattenColumns'));
    return Boolean(query && query.sortOrder != null);
  }
  /**
   * Determine whether the column is filtered and controlled
   * Controlled: the column passed the filteredValue prop
   * @param {String} dataIndex
   * @returns {Boolean}
   */
  _filterIsControlled(dataIndex) {
    const query = dataIndex && this.getQuery(dataIndex, this.getState('flattenColumns'));
    return Boolean(query && Array.isArray(query.filteredValue));
  }
  _filterShowIsControlled(dataIndex) {
    const query = dataIndex && this.getQuery(dataIndex, this.getState('flattenColumns'));
    return Boolean(query && (query.filterDropdownVisible === true || query.filterDropdownVisible === false));
  }
  _getSelectedRowKeys() {
    const rowSelection = this.getState('rowSelection');
    const selectedRowKeys = _get(rowSelection, 'selectedRowKeys', []);
    return [...selectedRowKeys];
  }
  _getSelectedRowKeysSet() {
    const rowSelection = this.getState('rowSelection');
    const selectedRowKeysSet = _get(rowSelection, 'selectedRowKeysSet', new Set());
    return selectedRowKeysSet;
  }
  _getDataSource() {
    return this.getProp('dataSource') || [];
  }
  _getRecord(realKey) {
    return _find(this.getProp('dataSource'), record => realKey != null && realKey !== '' && this.getRecordKey(record) === realKey);
  }
  _getRecordChildren(record) {
    return _get(record, this.getProp('childrenRecordName'));
  }
  _getPagination() {
    return this.getState('pagination') || {};
  }
  /**
   * Filters are considered valid if filteredValue exists
   */
  _getAllFilters(queries) {
    queries = queries || this.getState('queries');
    const filters = [];
    _each(queries, query => {
      if (Array.isArray(query.filteredValue) && (query.filteredValue.length || this._filterIsControlled(query.dataIndex))) {
        filters.push(query);
      }
    });
    return filters;
  }
  _getAllSorters(queries) {
    queries = queries || this.getState('queries');
    return _filter(queries, query => query.sorter && query.sortOrder);
  }
  _filterQueries(targetQuery, queries) {
    let keys = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : ['dataIndex'];
    queries = queries == null ? this.getState('queries') : queries;
    const filteredQueries = [];
    const filteredIndexes = [];
    _each(queries, (itQuery, index) => {
      const flag = _some(keys, k => k && targetQuery[k] != null && targetQuery[k] === itQuery[k]);
      if (flag) {
        filteredQueries.push(itQuery);
        filteredIndexes.push(index);
      }
    });
    return {
      filteredQueries,
      filteredIndexes
    };
  }
  _mergeToQueries(query, queries) {
    let keys = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : ['dataIndex'];
    queries = queries == null ? this.getState('queries') : queries;
    queries = [...queries];
    query = Object.assign({}, query);
    const {
      filteredQueries,
      filteredIndexes
    } = this._filterQueries(query, queries, keys);
    _each(filteredQueries, (curQuery, idx) => {
      // assign(curQuery, query);
      queries[filteredIndexes[idx]] = Object.assign({}, query);
    });
    return queries;
  }
  /**
   * get record real key
   * @param {RecordType} record
   * @returns {string}
   */
  getRecordKey(record) {
    if (!record) {
      return undefined;
    }
    const rowKey = this.getProp('rowKey');
    return typeof rowKey === 'function' ? rowKey(record) : _get(record, rowKey);
  }
  isEmpty(dataSource) {
    dataSource = dataSource == null ? this.getProp('dataSource') : dataSource;
    return !(Array.isArray(dataSource) && dataSource.length > 0);
  }
  handleSelectRow(realKey, selected, e) {
    this.stopPropagation(e);
    if (typeof selected === 'boolean' && realKey != null) {
      const selectedRowKeys = this._getSelectedRowKeys();
      let foundIdx = -1;
      const selectedRow = this.getSelectedRows(null, [realKey])[0];
      let selectedRows;
      if ((foundIdx = selectedRowKeys.indexOf(realKey)) > -1 && selected === false) {
        selectedRowKeys.splice(foundIdx, 1);
        selectedRows = this.getSelectedRows(null, selectedRowKeys);
        if (!this._selectionIsControlled()) {
          this._adapter.setSelectedRowKeys(selectedRowKeys);
        }
        this._adapter.notifySelect(selectedRow, selected, selectedRows, e);
        this._adapter.notifySelectionChange(selectedRowKeys, selectedRows);
      } else if (selectedRowKeys.indexOf(realKey) === -1 && selected === true) {
        selectedRowKeys.push(realKey);
        selectedRows = this.getSelectedRows(null, selectedRowKeys);
        if (!this._selectionIsControlled()) {
          this._adapter.setSelectedRowKeys(selectedRowKeys);
        }
        this._adapter.notifySelect(selectedRow, selected, selectedRows, e);
        this._adapter.notifySelectionChange(selectedRowKeys, selectedRows);
      }
    }
  }
  /**
   * select all rows
   * @param {*} selected The future state of the select all button
   * @param {*} e
   */
  handleSelectAllRow(selected, e) {
    this.stopPropagation(e);
    if (typeof selected === 'boolean') {
      const curSelectedRowKeys = this._getSelectedRowKeys();
      let selectedRowKeys = [...curSelectedRowKeys];
      const selectedRowKeysSet = this._getSelectedRowKeysSet();
      let allRowKeys = [...this._adapter.getCachedFilteredSortedRowKeys()];
      const disabledRowKeys = this._adapter.getAllDisabledRowKeys();
      const disabledRowKeysSet = this._adapter.getAllDisabledRowKeysSet();
      let changedRowKeys;
      // Select all, if not disabled && not in selectedRowKeys
      if (selected) {
        for (const key of allRowKeys) {
          if (!disabledRowKeysSet.has(key) && !selectedRowKeysSet.has(key)) {
            selectedRowKeys.push(key);
          }
        }
        allRowKeys = pullAll(allRowKeys, [...disabledRowKeys, ...curSelectedRowKeys]);
        changedRowKeys = [...allRowKeys];
      } else {
        selectedRowKeys = pullAll(selectedRowKeys, allRowKeys);
        changedRowKeys = [...curSelectedRowKeys];
      }
      const changedRows = this.getSelectedRows(null, changedRowKeys || []);
      const selectedRows = this.getSelectedRows(null, selectedRowKeys || []);
      if (!this._selectionIsControlled()) {
        this._adapter.setSelectedRowKeys(selectedRowKeys);
      }
      this._adapter.notifySelectAll(selected, selectedRows, changedRows, e);
      this._adapter.notifySelectionChange(selectedRowKeys, selectedRows);
    }
  }
  /**
   * row keys => rows
   * @param {*} dataSource
   * @param {*} selectedRowKeys
   * @param {*} selectedRowKeysSet Recursive optimization
   */
  getSelectedRows(dataSource, selectedRowKeys, selectedRowKeysSet) {
    dataSource = dataSource == null ? this._getDataSource() : dataSource;
    selectedRowKeys = selectedRowKeys == null ? this._getSelectedRowKeys() : selectedRowKeys;
    if (!_isSet(selectedRowKeysSet)) {
      selectedRowKeysSet = new Set(selectedRowKeys);
    }
    const childrenRecordName = this.getProp('childrenRecordName');
    const selectedRows = [];
    if (_isSet(selectedRowKeysSet) && selectedRowKeysSet.size && Array.isArray(dataSource) && dataSource.length) {
      // Time complexity optimization, replace the includes operation of array with has of set
      selectedRows.push(...dataSource.filter(data => selectedRowKeysSet.has(this.getRecordKey(data))));
      if (selectedRows.length < selectedRowKeys.length) {
        for (const item of dataSource) {
          const children = _get(item, childrenRecordName);
          if (Array.isArray(children) && children.length) {
            const rows = this.getSelectedRows(children, selectedRowKeys, selectedRowKeysSet);
            selectedRows.push(...rows);
          }
        }
      }
    }
    return selectedRows;
  }
  getAllDisabledRowKeys(dataSource, getCheckboxProps) {
    dataSource = dataSource == null ? this._getDataSource() : dataSource;
    getCheckboxProps = getCheckboxProps == null ? _get(this.getProp('rowSelection'), 'getCheckboxProps') : getCheckboxProps;
    const childrenRecordName = this.getProp('childrenRecordName');
    const disabledRowKeys = [];
    if (Array.isArray(dataSource) && dataSource.length && typeof getCheckboxProps === 'function') {
      for (const record of dataSource) {
        const props = getCheckboxProps(record);
        if (props && props.disabled) {
          disabledRowKeys.push(this.getRecordKey(record));
        }
        const children = _get(record, childrenRecordName);
        if (Array.isArray(children) && children.length) {
          const keys = this.getAllDisabledRowKeys(children, getCheckboxProps);
          disabledRowKeys.push(...keys);
        }
      }
    }
    return disabledRowKeys;
  }
  getAllRowKeys(dataSource) {
    dataSource = dataSource == null ? this._getDataSource() : dataSource;
    const childrenRecordName = this.getProp('childrenRecordName');
    const allRowKeys = [];
    if (Array.isArray(dataSource) && dataSource.length) {
      for (const record of dataSource) {
        const childrenRowKeys = [];
        const children = _get(record, childrenRecordName);
        if (Array.isArray(children) && children.length) {
          childrenRowKeys.push(...this.getAllRowKeys(children));
        }
        allRowKeys.push(this.getRecordKey(record), ...childrenRowKeys);
      }
    }
    return allRowKeys;
  }
  /**
   * Check if the selected item is in allRowKeysSet
   * @param {Array} selectedRowKeys
   * @param {Set} allRowKeysSet
   */
  hasRowSelected(selectedRowKeys, allRowKeysSet) {
    return Boolean(Array.isArray(selectedRowKeys) && selectedRowKeys.length && _isSet(allRowKeysSet) && allRowKeysSet.size && selectedRowKeys.filter(key => allRowKeysSet.has(key)).length);
  }
  /**
   * expand processing function
   * @param {Boolean} expanded
   * @param {String} realKey
   * @param {Event} domEvent
   */
  handleRowExpanded(expanded, realKey, domEvent) {
    this.stopPropagation(domEvent);
    const expandedRowKeys = [...this.getState('expandedRowKeys')];
    const index = expandedRowKeys.indexOf(realKey);
    const keyIsValid = typeof realKey === 'string' || typeof realKey === 'number';
    if (keyIsValid && expanded && index === -1) {
      expandedRowKeys.push(realKey);
    } else if (keyIsValid && !expanded && index > -1) {
      expandedRowKeys.splice(index, 1);
    }
    if (!this._rowExpansionIsControlled()) {
      this._adapter.setExpandedRowKeys(expandedRowKeys);
    }
    const expandedRows = this.getSelectedRows(null, expandedRowKeys);
    let expandedRow = this.getSelectedRows(null, [realKey])[0];
    // groups record processing
    const groups = this._getGroups();
    if (groups) {
      // Construct group expandRow
      if (groups.has(realKey)) {
        expandedRow = {
          groupKey: realKey
        };
      }
      // If expandedRowKeys includes groupKey, add to expandedRows
      for (let i = 0, len = expandedRowKeys.length; i < len; i++) {
        if (groups.has(realKey)) {
          expandedRows.push({
            groupKey: expandedRowKeys[i]
          });
        }
      }
    }
    this._adapter.notifyExpand(expanded, expandedRow, domEvent);
    this._adapter.notifyExpandedRowsChange(expandedRows);
  }
  /**
   * get state.groups
   * @returns {Map|Null}
   */
  _getGroups() {
    const groupBy = this._adapter.getProp('groupBy');
    if (groupBy !== null) {
      const groups = this._adapter.getState('groups');
      return groups;
    }
    return null;
  }
  /**
   * Determine whether you have selected all except for disabled
   * @param {Set} selectedRowKeysSet
   * @param {Set} disabledRowKeysSet
   * @param {Array} allKeys keys after sorted and filtered
   */
  allIsSelected(selectedRowKeysSet, disabledRowKeysSet, allKeys) {
    const filteredAllKeys = _filter(allKeys, key => key != null && !disabledRowKeysSet.has(key));
    if (filteredAllKeys && filteredAllKeys.length) {
      for (const key of filteredAllKeys) {
        if (key != null && !selectedRowKeysSet.has(key)) {
          return false;
        }
      }
      return true;
    } else {
      return false;
    }
  }
  /**
   * This function is not used yet
   * @param {*} selectedRowKeys
   * @param {*} allKeys
   */
  allIsNotSelected(selectedRowKeys, allKeys) {
    for (const key of allKeys) {
      if (key != null && Array.isArray(selectedRowKeys) && selectedRowKeys.includes(key)) {
        return true;
      }
    }
    return false;
  }
  formatPaginationInfo() {
    let pagination = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    let defaultPageText = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
    let info = '';
    const formatPageText = _get(this.getProp('pagination'), 'formatPageText');
    const {
      total,
      pageSize,
      currentPage
    } = pagination;
    const currentStart = Math.min((currentPage - 1) * pageSize + 1, total);
    const currentEnd = Math.min(currentPage * pageSize, total);
    if (formatPageText || formatPageText !== false && defaultPageText && total > 0) {
      info = typeof formatPageText === 'function' ? formatPageText({
        currentStart,
        currentEnd,
        total
      }) : defaultPageText.replace('${currentStart}', currentStart).replace('${currentEnd}', currentEnd).replace('${total}', total);
    }
    return info;
  }
  toggleShowFilter(dataIndex, visible) {
    let filterObj = this.getQuery(dataIndex);
    const filterDropdownVisible = visible;
    filterObj = Object.assign(Object.assign({}, filterObj), {
      filterDropdownVisible
    });
    if (!this._filterShowIsControlled()) {
      // this._adapter.setQuery({
      //     ...filterObj,
      //     filterDropdownVisible,
      // });
    }
    this._adapter.notifyFilterDropdownVisibleChange(filterDropdownVisible, dataIndex);
  }
  /**
   * Called when the filter changes
   * @param {*} dataIndex
   * @param {*} data
   */
  handleFilterSelect(dataIndex) {
    let data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    let query = this.getQuery(dataIndex);
    let queries = [...this._adapter.getState('queries')];
    const {
      filteredValue
    } = data;
    query = Object.assign(Object.assign({}, query), {
      filteredValue
    });
    queries = mergeQueries(query, queries);
    const mergedQueries = this._mergeToQueries(query, null);
    const filters = this._getAllFilters(mergedQueries);
    if (!this._filterIsControlled(dataIndex)) {
      this._adapter.setQueries(queries);
      this.handleClickFilterOrSorter(queries);
    }
    this._notifyChange(null, filters);
  }
  /**
   * Click the sort button to call
   * @param {*} column
   * @param {*} e
   */
  handleSort() {
    let column = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    let e = arguments.length > 1 ? arguments[1] : undefined;
    this.stopPropagation(e);
    const {
      dataIndex
    } = column;
    let queries = this.getState('queries');
    let curQuery = null;
    queries = [...queries];
    _each(queries, (query, idx, arr) => {
      if (query.sorter) {
        const sorterObj = Object.assign({}, query);
        const stateSortOrder = _get(sorterObj, 'sortOrder');
        const defaultSortOrder = _get(sorterObj, 'defaultSortOrder', false);
        let querySortOrder = this.isSortOrderValid(stateSortOrder) ? stateSortOrder : defaultSortOrder;
        if (dataIndex && dataIndex === sorterObj.dataIndex) {
          if (querySortOrder === strings.SORT_DIRECTIONS[0]) {
            querySortOrder = strings.SORT_DIRECTIONS[1];
          } else if (querySortOrder === strings.SORT_DIRECTIONS[1]) {
            querySortOrder = false;
          } else {
            querySortOrder = strings.SORT_DIRECTIONS[0];
          }
        } else {
          // This results in the current click only supports single column sorting
          querySortOrder = false;
        }
        arr[idx] = Object.assign(Object.assign({}, sorterObj), {
          sortOrder: querySortOrder
        });
        if (dataIndex === sorterObj.dataIndex) {
          curQuery = arr[idx];
        }
      }
    });
    if (!this._sorterIsControlled(dataIndex)) {
      this._adapter.setQueries(queries);
      this.handleClickFilterOrSorter(queries);
    }
    // notify sort event
    this._notifyChange(null, null, curQuery, null);
  }
  /**
   * Recalculate the cached data after clicking filter or sorter
   * @param {*} queries
   */
  handleClickFilterOrSorter(queries) {
    const dataSource = [...this.getProp('dataSource')];
    const sortedDataSource = this.getFilteredSortedDataSource(dataSource, queries);
    const allDataDisabledRowKeys = this.getAllDisabledRowKeys(sortedDataSource);
    this.setCachedFilteredSortedDataSource(sortedDataSource);
    this.setAllDisabledRowKeys(allDataDisabledRowKeys);
    const pageData = this.getCurrentPageData(sortedDataSource);
    this.setAdapterPageData(pageData);
  }
  getQuery(dataIndex, queries) {
    queries = queries || this.getState('queries');
    if (dataIndex != null) {
      return _find(queries, query => query.dataIndex === dataIndex);
    }
    return undefined;
  }
  getCellWidths(flattenedColumns, flattenedWidths, ignoreScrollBarKey) {
    return this._adapter.getCellWidths(flattenedColumns, flattenedWidths, ignoreScrollBarKey);
  }
  setHeadWidths(headWidths, index) {
    return this._adapter.setHeadWidths(headWidths, index);
  }
  getHeadWidths(index) {
    return this._adapter.getHeadWidths(index);
  }
  mergedRowExpandable(record) {
    return this._adapter.mergedRowExpandable(record);
  }
  setBodyHasScrollbar(bodyHasScrollbar) {
    this._adapter.setBodyHasScrollbar(bodyHasScrollbar);
  }
}
export default TableFoundation;