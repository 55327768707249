import BaseFoundation from '../base/foundation';
export default class ToastListFoundation extends BaseFoundation {
  constructor(adapter) {
    super(Object.assign(Object.assign({}, ToastListFoundation.defaultAdapter), adapter));
  }
  hasToast(id) {
    const toastList = this._adapter.getState('list');
    return toastList.map(_ref => {
      let {
        id
      } = _ref;
      return id;
    }).includes(id);
  }
  addToast(toastOpts) {
    const toastList = this._adapter.getState('list');
    // const id = getUuid('toast');
    // let toastOpts = { ...opts, id };
    // console.log(toastOpts);
    toastList.push(toastOpts);
    this._adapter.updateToast(toastList, [], []);
    // return id;
  }

  updateToast(id, toastOpts) {
    let toastList = this._adapter.getState('list');
    toastList = toastList.map(toast => toast.id === id ? Object.assign(Object.assign({}, toast), toastOpts) : toast);
    const updatedItems = toastList.filter(toast => toast.id === id);
    this._adapter.updateToast(toastList, [], updatedItems);
  }
  removeToast(id) {
    let toastList = this._adapter.getState('list');
    const removedItems = [];
    toastList = toastList.filter(toastOpts => {
      if (toastOpts.id === id) {
        removedItems.push(toastOpts);
        return false;
      }
      return true;
    });
    this._adapter.updateToast(toastList, removedItems, []);
  }
  destroyAll() {
    const toastList = this._adapter.getState('list');
    if (toastList.length > 0) {
      this._adapter.updateToast([], toastList, []);
    }
  }
}