var __rest = this && this.__rest || function (s, e) {
  var t = {};
  for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
  if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
    if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i])) t[p[i]] = s[p[i]];
  }
  return t;
};
import React from 'react';
import warning from '@douyinfe/semi-foundation/lib/es/utils/warning';
const generateOption = (child, parent, index) => {
  const childProps = child.props;
  if (!child || !childProps) {
    return null;
  }
  const option = Object.assign(Object.assign({
    value: childProps.value,
    // Drop-down menu rendering priority label value, children, value in turn downgrade
    label: childProps.label || childProps.children || childProps.value,
    _show: true,
    _selected: false,
    _scrollIndex: index
  }, childProps), {
    _parentGroup: parent
  });
  return option;
};
const getOptionsFromGroup = selectChildren => {
  let optionGroups = [];
  let options = [];
  const emptyGroup = {
    label: '',
    children: [],
    _show: false
  };
  // avoid null
  let childNodes = React.Children.toArray(selectChildren);
  childNodes = childNodes.filter(childNode => childNode && childNode.props);
  let type = '';
  let optionIndex = -1;
  childNodes.forEach(child => {
    if (child.type.isSelectOption) {
      type = 'option';
      optionIndex++;
      const option = generateOption(child, undefined, optionIndex);
      emptyGroup.children.push(option);
      options.push(option);
    } else if (child.type.isSelectOptionGroup) {
      type = 'group';
      // Avoid saving children (reactNode) by... removing other props from the group except children, causing performance problems
      let _a = child.props,
        {
          children
        } = _a,
        restGroupProps = __rest(_a, ["children"]);
      children = React.Children.toArray(children);
      const childrenOption = children.map(option => {
        optionIndex++;
        return generateOption(option, restGroupProps, optionIndex);
      });
      const group = Object.assign(Object.assign({}, child.props), {
        children: childrenOption,
        key: child.key
      });
      optionGroups.push(group);
      options = options.concat(childrenOption);
    } else {
      warning(true, '[Semi Select] The children of `Select` should be `Select.Option` or `Select.OptionGroup`');
    }
  });
  if (type === 'option') {
    optionGroups = [emptyGroup];
  }
  return {
    optionGroups,
    options
  };
};
export { generateOption, getOptionsFromGroup };